import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
	logoWrapper: {
		'@media (max-width: 574px)': {
			width: props => props.isGovXListing ? 'auto' : '200px',
			margin: '0px auto',
		},
	},
	logo: {
		width: '200px',
		'@media (max-width: 574px)': {
			height: '25px',
		},
		'@media (min-width: 575px)': {
			height: '40px',
		},
	},
	verifiedLogo: {
		width: '110px',
		height: '39px',
	},
	logoPath: {
		'@media (max-width: 574px)': {
			fill: props => props.isGovXListing ? '#fff' : props.settings.linkColor && props.settings.linkColor === '#fff' ? '#fff' : '#222'
		},
		'@media (min-width: 575px)': {
			fill: props => props.isGovXListing
				?	'#fff'
				:	props.settings.govXLogoColor
					?	props.settings.govXLogoColor
					:	props.settings.backgroundColor === 'transparent'
						?	'#fff'
						:	props.settings.linkColor
							?	props.settings.linkColor
							:	'#222',
		},
	},
	fillColor1: {
		fill: '#231F20',
	},
	fillColor2: {
		fill: '#EE6337',
	},
}

export const VerifiedByGovX = ({ classes, isCoBrand }) => (
	<svg className={ classes.verifiedLogo } width='110' height='39' alt='GovX ID' version='1.1' x='0px' y='0px' viewBox='0 0 1000 159.19'>
		<path className={isCoBrand ? classes.fillColor2 : classes.logoPath} d="M73.94,77.83,35,118.64H71.44a8.19,8.19,0,0,0,5.94-2.54l14.5-15.19a3.11,3.11,0,0,0,0-4.28Z"/>
		<path className={isCoBrand ? classes.fillColor2 : classes.logoPath} d="M77.38,33.13a8.23,8.23,0,0,0-5.94-2.54H35l81.85,85.75a7.24,7.24,0,0,0,5.23,2.23H127L81,70.39l4-4.18,47.93,50.08a7.4,7.4,0,0,0,5.34,2.28H143L89,62l4-4.19,55.47,58.11a8.48,8.48,0,0,0,6.14,2.63h4.36ZM65.43,37.64h3.51a.1.1,0,0,0,.1-.07l1.09-3.35a.1.1,0,0,1,.19,0l1.09,3.35a.1.1,0,0,0,.1.07H75a.1.1,0,0,1,.06.19l-2.84,2.06a.11.11,0,0,0,0,.12l1.09,3.34a.11.11,0,0,1-.16.12L70.29,41.4a.09.09,0,0,0-.12,0l-2.85,2.07a.11.11,0,0,1-.16-.12L68.25,40a.11.11,0,0,0,0-.12l-2.84-2.06A.11.11,0,0,1,65.43,37.64Zm-4.74.19-2.84,2.06a.11.11,0,0,0,0,.12l1.09,3.34a.11.11,0,0,1-.16.12L55.89,41.4a.09.09,0,0,0-.12,0l-2.84,2.07a.11.11,0,0,1-.16-.12L53.86,40a.13.13,0,0,0,0-.12L51,37.83a.11.11,0,0,1,.06-.19h3.52a.12.12,0,0,0,.1-.07l1.08-3.35a.11.11,0,0,1,.2,0L57,37.57a.1.1,0,0,0,.09.07h3.52A.11.11,0,0,1,60.69,37.83Zm11,11.27-2.84,2.07a.09.09,0,0,0,0,.11l1.09,3.35a.1.1,0,0,1-.16.11l-2.85-2.07a.13.13,0,0,0-.12,0l-2.84,2.07a.1.1,0,0,1-.16-.11l1.09-3.35a.09.09,0,0,0,0-.11L62,49.1a.11.11,0,0,1,.06-.19h3.52a.11.11,0,0,0,.1-.07l1.09-3.34a.1.1,0,0,1,.19,0L68,48.84a.1.1,0,0,0,.09.07h3.52A.11.11,0,0,1,71.67,49.1ZM82,60.27l-2.84,2.07a.09.09,0,0,0,0,.11l1.09,3.35a.1.1,0,0,1-.16.11l-2.84-2.07a.15.15,0,0,0-.13,0l-2.84,2.07a.1.1,0,0,1-.16-.11l1.09-3.35a.09.09,0,0,0,0-.11L72.3,60.27a.11.11,0,0,1,.06-.19h3.51A.1.1,0,0,0,76,60l1.09-3.34a.1.1,0,0,1,.19,0L78.34,60a.1.1,0,0,0,.1.07H82A.11.11,0,0,1,82,60.27Zm4.17-11.39L83.33,51a.09.09,0,0,0,0,.11l1.08,3.35a.1.1,0,0,1-.16.11l-2.84-2.06a.09.09,0,0,0-.12,0l-2.85,2.06a.1.1,0,0,1-.15-.11l1.08-3.35a.09.09,0,0,0,0-.11l-2.84-2.07a.1.1,0,0,1,.06-.18H80a.1.1,0,0,0,.09-.07l1.09-3.35a.11.11,0,0,1,.2,0l1.08,3.35a.12.12,0,0,0,.1.07h3.52A.1.1,0,0,1,86.18,48.88Z"/>
		<path className={isCoBrand ? classes.fillColor2 : classes.logoPath} d="M122.17,69.18l36.76-38.51H122.48a8.17,8.17,0,0,0-5.94,2.54L99.94,50.59l17.75,18.59A3.09,3.09,0,0,0,122.17,69.18Z"/>
		<path className={isCoBrand ? classes.fillColor2 : classes.logoPath} d="M183.28,10.21V127.75a1.9,1.9,0,0,1-1.48,1.86L97.39,148.93a1.87,1.87,0,0,1-.86,0L12.12,129.61a1.9,1.9,0,0,1-1.49-1.86V10.21H183.28M193.42.08H.5V127.75a12,12,0,0,0,9.36,11.74l84.41,19.32a12.21,12.21,0,0,0,5.38,0l84.41-19.32a12,12,0,0,0,9.36-11.74V.08Z"/>
		<path className={isCoBrand ? classes.fillColor1 : classes.logoPath} d="M358.8,30.27H259.38A12.35,12.35,0,0,0,247,42.62v63.9a12.35,12.35,0,0,0,12.35,12.35H358.8a12.35,12.35,0,0,0,12.35-12.35V74.23A5.26,5.26,0,0,0,365.89,69H329.13V82.28a5.43,5.43,0,0,0,5.43,5.42h10.87a1.81,1.81,0,0,1,1.8,1.8v8.08a1.8,1.8,0,0,1-1.8,1.8l-72.5-.09a1.81,1.81,0,0,1-1.8-1.8V51.92a1.8,1.8,0,0,1,1.8-1.79h72.5a1.8,1.8,0,0,1,1.8,1.79v7.59h23.92V42.62A12.35,12.35,0,0,0,358.8,30.27Z"/>
		<path className={isCoBrand ? classes.fillColor1 : classes.logoPath} d="M498.53,30.27H398.15a12.35,12.35,0,0,0-12.36,12.35v64A12.35,12.35,0,0,0,398.15,119H498.53a12.35,12.35,0,0,0,12.35-12.35v-64A12.35,12.35,0,0,0,498.53,30.27ZM486.78,97a2.09,2.09,0,0,1-.68,1.78,5.14,5.14,0,0,1-2.77.5h-70a5.22,5.22,0,0,1-2.68-.5A2,2,0,0,1,409.9,97V52.41a2,2,0,0,1,.77-1.77,5.1,5.1,0,0,1,2.68-.51h70a5,5,0,0,1,2.77.51,2.06,2.06,0,0,1,.68,1.77Z"/>
		<path className={isCoBrand ? classes.fillColor1 : classes.logoPath} d="M610.93,35.79,580.85,94.11l-.1.21-.11-.21L550.57,35.79a10.25,10.25,0,0,0-9.12-5.52H518.2l43.38,84.28a8,8,0,0,0,7.13,4.32h24.07a8,8,0,0,0,7.14-4.32l43.37-84.28H620A10.23,10.23,0,0,0,610.93,35.79Z"/>
		<path className={isCoBrand ? classes.fillColor1 : classes.logoPath} d="M769.21,30.3h-30.3a8.31,8.31,0,0,0-6,2.56L710,56.9l-23-24a8.28,8.28,0,0,0-6-2.56h-30.3l42.31,44.33L650.75,119h30.72a7.29,7.29,0,0,0,5.27-2.25L710,92.35l23.24,24.35a7.25,7.25,0,0,0,5.26,2.25h30.73L726.9,74.63Z"/>
		<path className={isCoBrand ? classes.fillColor1 : classes.logoPath} d="M825.73,119h21.19V30.26H825.73a2.91,2.91,0,0,0-2.91,2.92v82.9A2.91,2.91,0,0,0,825.73,119Z"/>
		<path className={isCoBrand ? classes.fillColor1 : classes.logoPath} d="M999.5,92.27V57a26.72,26.72,0,0,0-26.72-26.73H874.4V119h98.38A26.72,26.72,0,0,0,999.5,92.27Zm-37.06,6.55L902,99.24a5.22,5.22,0,0,1-2.68-.5,2,2,0,0,1-.77-1.77V52.41a2,2,0,0,1,.77-1.78,5.22,5.22,0,0,1,2.68-.5l60.48.42a12.37,12.37,0,0,1,12.28,12.37V86.46A12.36,12.36,0,0,1,962.44,98.82Z"/>
	</svg>
)

export const GovXLogo = ({ classes, isGovXListing, settings, isCoBrand }) => (
	<div className={ classes.logoWrapper }>
		{
			isGovXListing
			?	<svg version='1.1' x='0px' y='0px'
			viewBox='0 0 325.77 54.69' className={classes.logo}>
	   <g>
		   <path className={classes.logoPath} d="M275.12,1.58C274.16,0.57,272.82,0,271.43,0h-22.64l50.83,53.25c0.85,0.89,2.02,1.39,3.24,1.39l3.05,0
			   l-28.56-29.92l2.48-2.6l29.77,31.1c0.87,0.9,2.06,1.42,3.31,1.42h2.92l-33.52-35.12l2.48-2.6l34.45,36.09
			   c0.99,1.04,2.37,1.63,3.81,1.63h2.71L275.12,1.58z M267.7,4.38h2.18c0.03,0,0.05-0.02,0.06-0.04l0.67-2.08
			   c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18c0.06,0,0.09,0.08,0.04,0.12l-1.77,1.28
			   c-0.02,0.02-0.03,0.05-0.02,0.07l0.68,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.07,0L268.87,8
			   c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28C267.61,4.46,267.63,4.38,267.7,4.38z
				M264.75,4.49l-1.77,1.28c-0.02,0.02-0.03,0.05-0.02,0.07l0.68,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28
			   c-0.02-0.02-0.05-0.02-0.07,0L259.93,8c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28
			   c-0.05-0.04-0.02-0.12,0.04-0.12h2.18c0.03,0,0.05-0.02,0.06-0.04l0.67-2.08c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08
			   c0.01,0.03,0.03,0.04,0.06,0.04h2.18C264.78,4.38,264.8,4.46,264.75,4.49z M271.57,11.49l-1.77,1.28
			   c-0.02,0.02-0.03,0.04-0.02,0.07l0.67,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.07,0L266.75,15
			   c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28c-0.05-0.04-0.02-0.12,0.04-0.12h2.18
			   c0.03,0,0.05-0.02,0.06-0.04l0.68-2.08c0.02-0.06,0.1-0.06,0.12,0l0.67,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18
			   C271.6,11.38,271.62,11.46,271.57,11.49z M278,18.43l-1.77,1.28c-0.02,0.02-0.03,0.05-0.02,0.07l0.67,2.08
			   c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.08,0l-1.77,1.28c-0.05,0.04-0.12-0.01-0.1-0.07l0.68-2.08
			   c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28c-0.05-0.04-0.02-0.12,0.04-0.12h2.18c0.03,0,0.05-0.02,0.06-0.04l0.67-2.08
			   c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18C278.02,18.32,278.05,18.39,278,18.43z M280.58,11.36
			   l-1.77,1.28c-0.02,0.02-0.03,0.05-0.02,0.07l0.67,2.08c0.02,0.06-0.05,0.11-0.1,0.07l-1.77-1.28c-0.02-0.02-0.05-0.02-0.07,0
			   l-1.77,1.28c-0.05,0.04-0.12-0.01-0.1-0.07l0.67-2.08c0.01-0.03,0-0.06-0.02-0.07l-1.77-1.28c-0.05-0.04-0.02-0.12,0.04-0.12h2.18
			   c0.03,0,0.05-0.02,0.06-0.04l0.68-2.08c0.02-0.06,0.1-0.06,0.12,0l0.68,2.08c0.01,0.03,0.03,0.04,0.06,0.04h2.18
			   C280.61,11.24,280.63,11.32,280.58,11.36z"/>
		   <g>
			   <path className={classes.logoPath} d="M272.98,29.34l-24.19,25.34h22.64c1.39,0,2.73-0.57,3.69-1.58l9.01-9.44c0.71-0.74,0.71-1.91,0-2.66
				   L272.98,29.34z"/>
			   <path className={classes.logoPath} d="M302.93,23.96l22.83-23.92h-22.64c-1.39,0-2.73,0.57-3.69,1.58l-10.31,10.8l11.02,11.54
				   C300.91,24.76,302.18,24.76,302.93,23.96z"/>
		   </g>
	   </g>
	   <g>
		   <path className={classes.logoPath} d="M0,7.61V47c0,4.2,3.41,7.61,7.61,7.61H68.9c4.2,0,7.61-3.41,7.61-7.61V27.1c0-1.79-1.45-3.25-3.25-3.25H50.61
			   v8.2c0,1.85,1.5,3.35,3.35,3.35h6.7c0.61,0,1.11,0.5,1.11,1.11v4.98c0,0.61-0.5,1.11-1.11,1.11l-44.69-0.06
			   c-0.61,0-1.11-0.5-1.11-1.11V13.35c0-0.61,0.5-1.11,1.11-1.11h44.69c0.61,0,1.11,0.5,1.11,1.11v4.67h14.75V7.61
			   C76.51,3.41,73.1,0,68.9,0H7.61C3.41,0,0,3.41,0,7.61z"/>
		   <path className={classes.logoPath} d="M155.02,0H93.15c-4.2,0-7.61,3.41-7.61,7.61v39.46c0,4.2,3.41,7.61,7.61,7.61h61.87c4.2,0,7.61-3.41,7.61-7.61
			   V7.61C162.63,3.41,159.23,0,155.02,0z M147.78,13.65v27.46c0,0.52-0.14,0.88-0.42,1.09c-0.27,0.21-0.85,0.31-1.71,0.31h-43.13
			   c-0.79,0-1.34-0.1-1.65-0.31c-0.31-0.21-0.47-0.57-0.47-1.09V13.65c0-0.52,0.16-0.88,0.47-1.09c0.31-0.21,0.87-0.31,1.65-0.31
			   h43.13c0.86,0,1.44,0.11,1.71,0.31C147.64,12.76,147.78,13.13,147.78,13.65z"/>
		   <path className={classes.logoPath} d="M229.92,0c-2.37,0-4.54,1.31-5.62,3.4l-18.54,35.95l-0.07,0.12l-0.07-0.12L187.1,3.4
			   c-1.08-2.09-3.25-3.4-5.62-3.4h-14.33l26.74,51.95c0.84,1.64,2.54,2.66,4.4,2.66h0.02h14.8h0.02c1.86,0,3.55-1.03,4.4-2.66
			   L244.25,0H229.92z"/>
	   </g>
	   </svg>
			:	<VerifiedByGovX classes={ classes } isCoBrand={ isCoBrand } />
		}
	</div>
)

GovXLogo.propTypes = {
	classes: PropTypes.object.isRequired,
	isGovXListing: PropTypes.bool,
	settings: PropTypes.object,
	isCoBrand: PropTypes.bool,
}

export default injectSheet(styles)(GovXLogo)