import React from "react";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";

import routes from "./setup/routes";
import App from "./containers/App";
import { init } from "./app";
import { Router } from "react-router-dom";
init().then(({ store, history }) => {
  const container = document.querySelector("#root");
  const root = createRoot(container);

  const renderApp = () =>
    root.render(
      <Provider store={store}>
        <Router history={history}>
          <App routes={routes} store={store}>
            {renderRoutes(routes)}
          </App>
        </Router>
      </Provider>
    );

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./containers/App", renderApp);
  }

  renderApp();
});
