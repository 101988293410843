export const authorizeRoutes = (matched, location, history, options) => {
	if (matched.length > 0) {
		const authorizations = matched
			.filter(
				({ route }) =>
					(route.component && route.component.authorize) || (route.lifecycle && route.lifecycle.authorize)
			)
			.map(({ route, match }) => {
				if (route.lifecycle) return route.lifecycle.authorize(match, location, options, history)
				return route.component.authorize(match, location, options, history)
			})

		if (authorizations && authorizations.length > 0) return Promise.all(authorizations).then(x => x)
	}
	return Promise.resolve([{ authorized: true }])
}

export const fetchRoutes = (matched, location, options) => {
	if (matched.length > 0) {
		const promises = matched
			.filter(
				({ route }) => (route.component && route.component.fetch) || (route.lifecycle && route.lifecycle.fetch)
			)
			.map(({ route, match }) => {
				if (route.lifecycle)
					return route.lifecycle.fetch(match, location, options).then(x => ({
						key: route.name,
						data: x,
					}))
				return route.component.fetch(match, location, options)
			})

		if (promises && promises.length > 0)
			return Promise.all(promises).then(x => {
				let i = 0
				let results = {}

				x.forEach(d => {
					if (d && d.key) results[d.key] = d.data
					else results[`undefined${i++}`] = d
				})

				return results
			})
	}
	return Promise.resolve({})
}

export const validateRoutes = (matched, location, options) => {
	if (matched.length > 0) {
		const validatations = matched
			.filter(
				({ route }) =>
					(route.component && route.component.validate) || (route.lifecycle && route.lifecycle.validate)
			)
			.map(({ route, match }) => {
				if (route.lifecycle) return route.lifecycle.validate(match, location, options)

				return route.component.validate(match, location, options)
			})

		if (validatations && validatations.length > 0) return Promise.all(validatations).then(x => x)
	}
	return Promise.resolve([{ valid: true }])
}
