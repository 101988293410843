import {
	RESEND_VERIFICATION_EMAIL_SUCCESS,
} from './users'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const showNotification = message => ({ type: SHOW_NOTIFICATION, message })
export const clearNotification = () => ({ type: 'CLEAR_NOTIFICATION' })

export const STATE_KEY = 'notifications'

export const DEFAULT_STATE = {
	message: null,
	showing: false,
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case CLEAR_NOTIFICATION:
			return DEFAULT_STATE
		case SHOW_NOTIFICATION:
			return {
				...state,
				message: action.message,
				showing: true,
			}
		case RESEND_VERIFICATION_EMAIL_SUCCESS:
			return {
				...state,
				message: "Activation email sent",
				showing: true,
			}

		default:
			return state
	}
}

export default reducer

export const selectNotification = state => state.notifications
