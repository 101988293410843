import React from 'react'
import GovXHeader from './GovXHeader'
import CoBrandedHeader from './CoBrandedHeader'
import PrivateLabelHeader from './PrivateLabelHeader'

export default (listing, uiSettings, listingType) => {
	switch(listingType) {
		case 'CoBranded':
			return <CoBrandedHeader settings={ uiSettings.header } listingName={ listing.name } />
		case 'PrivateLabel':
			return <PrivateLabelHeader settings={ uiSettings.header } listingName={ listing.name } />
		case 'GovX':
		default:
			return <GovXHeader settings={ uiSettings.header } />
	}
}