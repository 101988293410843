import React, { Component } from "react";
import store from "store";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import { withRouter, Route } from "react-router";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { refresh, pageRendered } from "../app";
import { authorizeRoutes, fetchRoutes, validateRoutes } from "./fetchRoutes";
import theme from "../theme";
import ReactAI from "../utils/ReactAI";
import { CssBaseline } from "@mui/material";

export const LOCATION_CHANGE = "LOCATION_CHANGE";

class App extends Component {
  state = {
    previousLocation: null,
    isAppFetching: false,
    error: null,
    initialMount: false,
    delayed: false,
    redirect: false,
    branch: null,
    ready: false,
  };

  constructor(props) {
    super(props);

    this.setManualScroll();

    // this.documentListener = e => {
    // 	const trackingEvent = getTrackingEvent(e)
    // 	if (trackingEvent && trackingEvent.analytics)
    // 		this.props.dispatch({ ...trackingEvent.analytics, e })
    // }

    document.addEventListener("click", this.documentListener);
    window.addEventListener("beforeunload", this.captureScroll);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((payload) => {
      this.props.dispatch({ type: LOCATION_CHANGE, payload });
    });
    this.loadApp(this.props, true);
  }

  componentWillReceiveProps(nextProps) {
    const current = `${this.props.location.pathname}`;
    const next = `${nextProps.location.pathname}`;

    const navigated = current !== next;
    if (navigated) {
      this.captureScroll(this.props.location, nextProps.location);
      this.loadApp(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  setManualScroll = () => {
    if (window && "scrollRestoration" in window.history)
      history.scrollRestoration = "manual";
  };

  captureScroll = () => {
    let { location } = this.props;
    let { branch } = this.state;

    if (branch && branch.route && branch.route.restoreScroll) {
      store.set(`scroll-${location.key}`, {
        top: window.document.documentElement.scrollTop,
        behavior: "instant",
      });
    }
  };

  restoreScroll = () => {
    let { history } = this.props;
    let { branch } = this.state;

    if (
      history.action === "PUSH" ||
      history.action === "REPLACE" ||
      !branch ||
      !branch.route ||
      !branch.route.restoreScroll
    ) {
      if (!history.location.state || !history.location.state.noScroll) {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }
    } else {
      let scroll = store.get(`scroll-${history.location.key}`);
      if (scroll) {
        store.remove(`scroll-${history.location.key}`);
        window.scrollTo(scroll);
      }
    }
  };

  handleRedirect = (url, addReturnUrl) => {
    if (!url) {
      return;
    }
    const { history } = this.props;

    var search = addReturnUrl
      ? "?returnUrl=" + encodeURIComponent(window.location.href)
      : "";

    if (url === "/login") {
      window.location.href = url + search;
    } else {
      // Replace instead of push to prevent browser back button going to invalid url
      history.replace({ pathname: url });
    }
  };

  loadApp(props, initialLoad) {
    const { dispatch, location, routes, store, history } = props;

    if (!this.state.redirect) {
      this.setState({
        previousLocation: this.props.location,
        isAppFetching: true,
        error: null,
        delayed: false,
        ready: false,
      });
    }

    let that = this;
    setTimeout(() => {
      if (!this.state.ready) this.setState({ ...this.state, delayed: true });
    }, 250);

    const pageViewTelemetry = { uri: location.pathname };

    ReactAI.startTrackPage(pageViewTelemetry);

    var telemetry = {
      preload: { start: null, stop: null },
      fetch: { start: null, stop: null },
      render: { start: null, stop: null },
    };

    ReactAI.startPageLoad(location);
    telemetry.preload.start = Date.now();

    let preload = initialLoad ? Promise.resolve({}) : refresh();
    preload.then(() => {
      telemetry.preload.stop = Date.now();
      let matched = matchRoutes(routes, location.pathname);

      authorizeRoutes(matched, location, history, {
        dispatch: store.dispatch,
        getState: store.getState,
      }).then((authorizations) => {
        let unauthorized = authorizations.find((a) => !a.authorized);

        if (unauthorized) {
          ReactAI.stopPageLoad({ ...telemetry, unauthorized: true });
          ReactAI.stopTrackPage(pageViewTelemetry);
          return this.handleRedirect(unauthorized.redirect || "/login", true);
        }

        telemetry.fetch.start = Date.now();

        fetchRoutes(matched, location, { dispatch, getState: store.getState })
          .then((data) => {
            validateRoutes(matched, location, {
              dispatch,
              getState: store.getState,
            }).then((validations) => {
              telemetry.fetch.stop = Date.now();

              let invalid = validations.find((v) => !v.valid);
              if (invalid) {
                this.setState({ redirect: true });
                ReactAI.stopPageLoad({ ...telemetry, invalid: true });
                ReactAI.stopTrackPage(pageViewTelemetry);
                return this.handleRedirect(invalid.redirect || "/404", false);
              }

              telemetry.render.start = Date.now();
              ReactAI.stopTrackPage(pageViewTelemetry);

              let branch = { ...matched.find((m) => m.route.page) };

              if (branch)
                branch.settings =
                  branch.route && branch.route.config
                    ? typeof branch.route.config === "function"
                      ? branch.route.config({
                          data,
                          match: branch.match,
                          getState: store.getState,
                        })
                      : { ...branch.route.config }
                    : {};

              this.setState({
                previousLocation: null,
                isAppFetching: false,
                initialMount: true,
                redirect: false,
                branch,
                ready: true,
                telemetry,
              });

              // pageView(branch)
            }); // validate routes
          }) // fetch routes
          .catch((err) => {
            this.setState({
              isAppFetching: false,
              error: err,
            });

            ReactAI.ai().trackException({ exception: err });
          });
      }); // authorize routes
    }); // pre load
  }

  componentDidCatch(error, info) {
    ReactAI.ai().trackException({ exception: error });

    this.setState({
      error: error,
    });
  }

  componentWillUnmount() {
    this.unlisten();
    document.removeEventListener("click", this.documentListener);
  }

  render() {
    const { children, location } = this.props;
    const { error, previousLocation, initialMount, delayed, isAppFetching } =
      this.state;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ position: "relative" }}>
            <CssBaseline />
            {initialMount && !error && (
              <Route
                location={previousLocation || location}
                render={() => children}
              />
            )}
            {/* {
						error &&
						<ErrorPage error={ error } />
					} */}
            {/* { delayed && isAppFetching && <PageOverlay /> } */}
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  componentDidUpdate(prevProps) {
    let { ready, branch, telemetry } = this.state;

    if (ready && branch) {
      setTimeout(() => this.restoreScroll(), 0);
      setTimeout(() => pageRendered(branch), 0);
      setTimeout(() => {
        ReactAI.stopPageLoad({
          ...telemetry,
          render: {
            ...telemetry.render,
            stop: Date.now(),
          },
        });
      }, 1);
    }
  }
}

export default withRouter(connect()(App));
