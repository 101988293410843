import React from 'react'
import PropTypes from 'prop-types'
import ModalContainer from './ModalContainer'
import StyledLink from '../StyledLink'

export const WhatIsDMDCModal = ({}) => (
	<ModalContainer title='What is DMDC?'>
		<p>DMDC stands for Defense Manpower Data Center. Since 1974, the DMDC has kept track of military personnel for the US Department of Defense. Verifying membership this way simply means GovX automatically looks for you in the DMDC. If a record of your service is found, you'll be approved for GovX membership instantly.</p>
		<p><StyledLink contents='Learn more about the DMDC' to='https://support.govx.com/hc/en-us/articles/115000432551' newWindow={ true } /></p>
	</ModalContainer>
)

export default WhatIsDMDCModal