import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../../setup/route-constants'
import { fetchEntertainerEventTickets } from '../../../redux/actions'
import queryString from 'query-string'

const LoadableEntertainerEventsPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "tickets.entertainer.events" */ './EntertainerEventsPageContainer'),
	loading: () => null
})

export default {
	name: 'event tickets list for entertainer',
	path: RouteConstants.tickets.entertainerEvents.path,
	page: true,
	component: LoadableEntertainerEventsPageContainer,
	lifecycle: {
		fetch: (match, location, { dispatch }) => {
			let values = queryString.parse(location.search)
			let entertainerId = values.entertainerId
			let clientId = values.clientId
			return dispatch(fetchEntertainerEventTickets(entertainerId, clientId))
		},

		// authorize: (match, location, { getState }) => {
		// 	var user = selectUser(getState())
		// 	return { authorized : !!user && user.isApproved, redirect: '/' }
		// }
	},
	config: () => ({

	})
}