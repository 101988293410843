import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../setup/route-constants'

const LoadableOfferEventsPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "offerevents.events" */ './'),
	loading: () => null
})

export default {
	name: 'events',
	path: RouteConstants.offerEvents.events.path,
	page: true,
	exact: true,
	component: LoadableOfferEventsPageContainer,
	lifecycle: {
		
		// authorize: (match, location, { getState }) => {
		// 	var user = selectUser(getState())
		// 	return { authorized : !!user && user.isApproved, redirect: '/' }
		// }
	},
	config: () => ({

	})
}