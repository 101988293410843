import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import injectSheet from "react-jss";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    padding: " 20px",
    backgroundColor: "rgba(255, 255, 255, .6)",
    zIndex: 2000,
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
};

const PageOverlay = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.inner}>
      <CircularProgress style={{ color: "#00a1df" }} />
    </div>
  </div>
);

PageOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(injectSheet(styles));

export default enhance(PageOverlay);
