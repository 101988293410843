import React from 'react'
import { connect } from 'react-redux'
import WhatIsDMDCModal from './WhatIsDMDCModal'
import EmailLinkModal from './EmailLinkModal'
import EligibleGroupsModal from './EligibleGroupsModal'
import { compose, renderComponent } from 'recompose'

let MODAL_COMPONENTS = {
	'WHAT_IS_DMDC': WhatIsDMDCModal,
	'EMAIL_LINK': EmailLinkModal,
	'ELIGIBLE_GROUPS': EligibleGroupsModal,
	'DYNAMIC': props => {
		let { component, ...rest } = props
		let renderer = compose(renderComponent(component))
		return renderer(null)(rest)
	}
}

export const addModals = modals => {
	MODAL_COMPONENTS = {...MODAL_COMPONENTS, ...modals}
}

export const ModalRoot = ({modalType, modalProps}) => {
	if (!modalType) return null

	let SpecificModal = MODAL_COMPONENTS[modalType]
	return <SpecificModal {...modalProps} hideModal={() => dispatch(hideModal())} />
}

export default connect(state => state.modal)(ModalRoot)