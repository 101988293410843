export const cors = { mode: 'cors' }
export const includeCredentials = { credentials: 'include' }
export const jsonHeaders = {
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
}

export const jsonAcceptHeader = {
	headers: {
		'Accept': 'application/json'
	}
}

export const withBody = body => ({ body: body })

export const postJson = data => {
	let jsonBody = withBody(JSON.stringify(data))
	return {
		...cors,
		...includeCredentials,
		...jsonHeaders,
		...jsonBody,
		method: 'POST'
	}
}

export const getJson = () => {
	return {
		...cors,
		...includeCredentials,
		...jsonAcceptHeader,
		method: 'GET'
	}
}

export const postFormData = formData => {
	return {
		...cors,
		...includeCredentials,
		...jsonAcceptHeader,
		method: 'POST',
		body: formData
	}
}