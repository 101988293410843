import { renderNothing } from 'recompose'
import { composeTracking } from '../'

export const scriptUrl = '//cdn.inspectlet.com/inspectlet.js'

export const getTrackingData = () => {
	return {
		items: [
			['wid', 797306955]
		]
	}
}

export const track = data => {
	window.__insp = window.__insp || []
	for (let i = 0; i < data.items.length; i++) {
		window.__insp.push(data.items[i])
	}
}

const enhance = composeTracking({
	getScriptUrl: () => scriptUrl,
	preload: () => {
		let data = getTrackingData()
		track(data)
	}
})

export default enhance(renderNothing())