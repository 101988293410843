import { BorderColor } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";

export default createTheme({
  typography: {
    fontFamily: ["Geomanist Regular", "Helvetica", "sans-serif"].join(","),
    useNextVariants: true,
  },
  fontFamily: '"Geomanist Regular", Helvetica, sans-serif',
  palette: {
    primary1Color: "#0e5777",
    accent1Color: "#cf5928",
  },
  colors: {
    lightGray: "#e3e3e3",
    mediumGray: "#7b7e84",
    darkGray: "#2a2d36",
    lightBlue: "#0c9ac4",
    darkBlue: "#0e5777",
    white: "#ffffff",
    yellow: "#f7b918",
    red: "#bd2326",
    orange: "#d74f27",
    gold: "#b79042",
  },
  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     body: {
    //       fontSize: "0.875rem",
    //       lineHeight: 1.43,
    //       letterSpacing: "0.01071em",
    //     },
    //   },
    // },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#43a047",
        },
        message: {
          margin: "0 auto",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "5px 12px 6px",

          "&.Mui-checked": {
            color: "#0e5777",
          },
        },

        colorPrimary: {
          "&.Mui-checked": {
            color: "#0e5777",
          },
        },
        colorSecondary: {
          "&.Mui-checked": {
            color: "#0e5777",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "black",
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: "#0e5777",
          },
        },
        colorSecondary: {
          "&.Mui-checked": {
            color: "#0e5777",
          },
        },
      },
    },
    // https://mui.com/material-ui/migration/v5-component-changes/#replace-listitemclasses-prop
    // MuiListItem: {
    //   root: {
    //     fontFamily: '"Geomanist Regular", Helvetica, sans-serif',
    //     "&:hover": {
    //       textDecoration: "none",
    //       backgroundColor: "rgba(0,0,0,0.1)",
    //       // Reset on touch devices, it doesn't add specificity
    //       "@media (hover: none)": {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //   },
    //   button: {
    //     "&:hover": {
    //       textDecoration: "none",
    //       backgroundColor: "rgb(0,0,0,0.1)",
    //       // Reset on touch devices, it doesn't add specificity
    //       "@media (hover: none)": {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //   },
    // },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Geomanist Regular", Helvetica, sans-serif',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: "solid 1px #e3e3e3",
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#fff",
          },
          "&.Mui-focused": {
            backgroundColor: "#fff",
          },
        },
        underline: {
          "&:before": {
            bottom: "-1px",
          },
          "&:after": {
            bottom: "-1px",
            borderBottomColor: "#0e5777",
          },
          "&.Mui-error:after": {
            borderBottom: "solid 2px red",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: '"Geomanist Regular", Helvetica, sans-serif',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        ".MuiInput-underline": {
          display: "none",
          "&::after": {
            transform: "scaleX(0)",
            transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            borderBottom: "2px solid #0e5777",
          },
          "&:focused": {
            borderBottom: "2px solid #0e5777",
            transform: "scaleX(1)",
          },
          "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before":
            {
              borderBottom: "1px solid inherit",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                borderBottom: "1px solid inherit",
              },
            },
          "&.Mui-disabled:before": {
            borderBottom: "1px solid #0e5777",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Geomanist Regular", Helvetica, sans-serif',
          fontSize: "16px",
          "&.Mui-focused": {
            color: "#0e5777",
          },
          "&.Mui-error": {
            color: "rgb(155, 155, 155)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: '"Geomanist Regular", Helvetica, sans-serif',
          fontSize: "12px",
          "&.Mui-error": {
            color: "red",
          },
          "@media (max-width: 574px)": {
            padding: "0",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            background: "none",
            borderBottom: "2px solid #0e5777",
            transform: "scaleX(1)",
          },
        },
      },
    },
    // MuiButton: {
    //   root: {
    //     fontWeight: "400",
    //     fontSize: "0.875rem",
    //     boxSizing: "border-box",
    //     height: "45px",
    //     borderRadius: "2px",
    //     letterSpacing: "0.2px",
    //   },
    //   label: {
    //     fontFamily: '"Geomanist Medium", Helvetica, sans-serif',
    //   },
    //   contained: {
    //     backgroundColor: "#2A2D36",
    //     color: "white",
    //     "&:hover": {
    //       backgroundColor: "#16171B",
    //     },
    //     "&:active": {
    //       backgroundColor: "#646D85",
    //     },
    //     "&:disabled": {
    //       backgroundColor: "#E0E0E0",
    //       color: "white",
    //     },
    //   },

    //   containedPrimary: {
    //     backgroundColor: "#0e5777",
    //   },
    //   containedSecondary: {
    //     backgroundColor: "#0e5777",
    //   },
    // },
  },
});
