import { getJson, postJson } from './baseApi'

export const fetchDiscountFromToken = token => {
	return getJson(`/api/tickets/discount/from-token?token=${token}`)
}

export const fetchEntertainer = id => {
	return getJson(`/api/tickets/entertainers/${id}`)
}

export const fetchEntertainerEventTickets = (entertainerId, clientId) => {
	return getJson(`/api/tickets/entertainers/${entertainerId}/event-tickets?clientId=${clientId}`)
}
