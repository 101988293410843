import { setGlobalError } from '../redux/actions'

const errorMiddleware = store => next => action => {
	try {
		return next(action)
	} catch (err) {
		console.error('Unhandled exception caught in errorMiddleware', err)
		dispatch(setGlobalError({ error: err }))
		throw err
	}
}

export default errorMiddleware