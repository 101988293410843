let configuration = {
}

export const getConfiguration = () => {
	return configuration
}

export const setConfiguration = options => {
	configuration = { ...configuration, ...options }
	return configuration
}

export const getAPIDomain = () => {
	return configuration.apiDomain
}

export const getListing = () => {
	return configuration.listing
}

export const getAltAPIDomain = () => {
	return configuration.altApiDomain
}

export const getOffersDomain = () => {
	return configuration.offersDomain
}

export const getGooglePlacesApiKey = () => {
	return configuration.googlePlacesApiKey
}

export const getUISettings = () => {
	return configuration.uiSettings
}

export const getRequireEmailConfirmation = () => {
	return configuration.requireEmailConfirmation
		&& configuration.requireEmailConfirmation == "True"
}

export const getPartnerListingId = () => {
	return configuration.partnerListingId
}

export const getIconUrls = () => {
	return configuration.iconUrls
}