import { getJson, postJson, postFormData } from './baseApi'
import { getAltAPIDomain } from '../setup/configuration'

export const fetchOccupations = () => {
	return getJson('/api/occupations')
}

export const setOccupation = occupation => {
	return postJson('/api/verification/progress', {occupationId: occupation.id})
}

export const validateOccupationCompatibility = (occupationId, listingId) => {
	return getJson(`/api/verification/validate-occupation?occupationId=${ occupationId }&listingId=${ listingId }`)
}

export const saveProgress = progress => {
	return postJson('/api/verification/progress', progress)
}

export const fetchProgress = id => {
	return getJson(`/api/verification/progress/${id}`)
}

export const fetchAllowedVerificationTypes = progressId => {
	return postJson(`/api/verification/allowedtypes/${progressId}`, {})
}

export const fetchListingAdvertisement = () => {
	return getJson('/api/listings/advertisement')
}

export const addListingSpecialOfferUserSubscriptions = specialOfferIds => {
	return postJson('/api/listings/specialOfferUserSubscriptions', specialOfferIds)
}

export const fetchPrequalificationInfo = occupationId => {
	return getJson(`/api/occupations/${occupationId}/info`)
}

export const saveAutoDocumentVerificationRequest = formData => {
	return postFormData(getAltAPIDomain() + '/api/verification/request/doc-auto', formData, { timeout: 180000, useApiDomain : false })
}

export const saveManualDocumentVerificationRequest = request => {
	return postJson('/api/verification/request/doc-manual', request)
}

export const saveEmailVerificationRequest = request => {
	return postJson('/api/verification/request/email', request)
}

export const saveVaVerificationRequest = request => {
	return postJson('/api/verification/request/va', request)
}

export const savePhoneVerificationRequest = request => {
	return postJson('/api/verification/request/phone', request)
}

export const fetchGovernmentDepartments = () => {
	return getJson('/api/verification/government-departments')
}

export const autoProcessDMDC = request => {
	return postJson('/api/verification/request/dmdc-auto', request, { timeout: 30000 })
}

export const autoProcessDMDCFamily = request => {
	return postJson('/api/verification/request/dmdc-family-auto', request, { timeout: 30000 })
}

export const saveDmdcFamilyAutoDocumentVerificationRequest = formData => {
	return postFormData(getAltAPIDomain() + '/api/verification/request/dmdc-family-doc-auto', formData, { timeout: 180000, useApiDomain : false })
}

export const autoProcessAuto = request => {
	return postJson('/api/verification/request/auto', request)
}

export const manualProcessDMDC = request => {
	return postJson('/api/verification/request/dmdc-manual', request)
}

export const manualProcessDMDCFamily = request => {
	return postJson('/api/verification/request/dmdc-family-manual', request)
}

export const saveActivationCode = code => {
	return postJson('/api/verification/request/activation-code', {code})
}

export const fetchVerificationRequest = id => {
	return getJson(`/api/verification/request/${id}`)
}

export const fetchSpecialOffer = () => {
	return getJson('/api/specialoffers/current')
}