import loadjs from 'loadjs'

let _script = {}

const loadScript = (url, info, preload, success) => {
	let urlList = url.map ? url : [ url ]
	let key = ''
	urlList.map(scriptUrl => { key += scriptUrl + ';' })

	if (_script[key])
		return _script[key]

	return (_script[key] = new Promise(resolve => {
		preload(info)

		let p = urlList.map(urlScript => new Promise(urlResolve => {

			loadjs(urlScript, {
				success: () => {
					urlResolve(urlScript)
				}
			})
		}))

		Promise.all(p).then(v => {
			success(info)
			resolve()
		})
	}))
}

export default loadScript