import React from "react";
import PropTypes from "prop-types";
import MuiButton from "@mui/material/Button";
import { isSmallScreen } from "../../utils/device-util";

export const Button = ({
  label,
  type,
  icon,
  secondary,
  containerElement,
  children,
  customStyle,
  ...custom
}) => (
  <MuiButton
    type={type ? type : "submit"}
    label={label}
    variant="contained"
    fullWidth
    component={containerElement}
    sx={{
      "&": {
        fontWeight: "400",
        fontSize: "0.875rem",
        boxSizing: "border-box",
        height: "45px",
        borderRadius: "2px",
        letterSpacing: "0.2px",
        fontFamily: '"Geomanist Medium", Helvetica, sans-serif',
      },
      "&.MuiButton-contained": {
        backgroundColor: "#2A2D36",
        color: "white",
        "&:hover": {
          backgroundColor: "#16171B",
        },
        "&:active": {
          backgroundColor: "#646D85",
        },
        "&:disabled": {
          backgroundColor: "#E0E0E0",
          color: "white",
        },
      },

      // "&.MuiButton-containedPrimary": {
      //   backgroundColor: "#0e5777",
      // },
      "&.MuiButton-containedSecondary": {
        backgroundColor: "#0e5777",
      },
    }}
    style={{
      boxShadow: "none",
      height: "60px",
      borderRadius: "3px",
      fontSize: icon && isSmallScreen() ? "14px" : "0.875rem",
      textTransform: "none",
      ...customStyle,
    }}
    {...custom}
  >
    {!children && label}
    {children && children}
  </MuiButton>
);

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element.isRequired]),
  onClick: PropTypes.func,
  type: PropTypes.string,
  secondary: PropTypes.bool,
};

export default Button;
