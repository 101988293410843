import React from 'react'
import PropTypes from 'prop-types'
import ModalContainer from './ModalContainer'
import StyledLink from '../StyledLink'
import { Link }  from 'react-router'
import { connect } from 'react-redux'
import { goToVerificationType, hideModal } from '../../redux/actions'
import { selectManualVerificationTypes } from '../../redux/selectors'
import { history } from '../../history'
import { RouteConstants } from '../../setup/route-constants'
import injectSheet from 'react-jss'

const styles = {
	link: {
		color: '#0e5777',
		textDecoration: 'none',
		cursor: 'pointer',

		'&:hover, &:focus': {
			textDecoration: 'underline',
		},
	},
}

export const EmailLinkModal = ({
	goToUploadDocument,
	classes,
	types
}) => (
	<ModalContainer title="Didn't get the email?">
		<div style={{padding:'20px'}}>
		<p>Check your spam folder!</p>
		<p>If you still haven't received the email, call us at <a href='tel:18884685511' className={ classes.link }>888-468-5511</a>.</p>
		{
			types && types.length > 0 && types.find(x => x.id == 4) &&
			<p>You can also <StyledLink onClick={ goToUploadDocument } clickHandlerOnly contents='try uploading a document instead' />.</p>
		}</div>
	</ModalContainer>
)

EmailLinkModal.propTypes = {
	goToUploadDocument: PropTypes.func.isRequired,
	classes: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => ({
	types: selectManualVerificationTypes(state)
})

const mapDispatchToProps = dispatch => ({
	goToUploadDocument: () => {
		dispatch(goToVerificationType(4))
		dispatch(hideModal())
		history.push(RouteConstants.verification.verifyDoc.index.path)
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(EmailLinkModal))