import * as verificationApi from '../../api/verificationApi'
import { RouteConstants, oauthRedirect } from '../../setup/route-constants'
import { normalize, denormalize } from 'normalizr'
import { createSelector } from 'reselect'
import { occupation } from '../../api/schema'
import { DateMask } from '../../masks'
import { setProcessingMessage, endProcessing } from './processing'
import { WAITING_FOR_PHONECALL } from './users'
//actions

export const SAVE_PROGRESS_REQUEST = 'SAVE_PROGRESS_REQUEST'
export const SAVE_PROGRESS_SUCCESS = 'SAVE_PROGRESS_SUCCESS'
export const SAVE_PROGRESS_FAILURE = 'SAVE_PROGRESS_FAILURE'

export const saveVerificationProgress = progress => (dispatch, getState, { session }) => {
	dispatch({ type: SAVE_PROGRESS_REQUEST })
	return verificationApi
		.saveProgress(progress)
		.then(response => {
			session.setSession({ progressId: response.id })
			dispatch({ type: SAVE_PROGRESS_SUCCESS, progress: response })
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_PROGRESS_FAILURE, err })
			throw err
		})
}

export const FETCH_ADVERTISEMENT_REQUEST = 'FETCH_ADVERTISEMENT_REQUEST'
export const FETCH_ADVERTISEMENT_SUCCESS = 'FETCH_ADVERTISEMENT_SUCCESS'
export const FETCH_ADVERTISEMENT_FAILURE = 'FETCH_ADVERTISEMENT_FAILURE'

export const fetchListingAdvertisement = () => (dispatch, getState, { session, resolve }) => {
	dispatch({ type: FETCH_ADVERTISEMENT_REQUEST })

	return verificationApi
		.fetchListingAdvertisement()
		.then(response => {
			dispatch({ type: FETCH_ADVERTISEMENT_SUCCESS, advertisement: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_ADVERTISEMENT_FAILURE, err })
			throw err
		})
}

export const FETCH_SPECIAL_OFFER_REQUEST = 'FETCH_SPECIAL_OFFER_REQUEST'
export const FETCH_SPECIAL_OFFER_SUCCESS = 'FETCH_SPECIAL_OFFER_SUCCESS'
export const FETCH_SPECIAL_OFFER_FAILURE = 'FETCH_SPECIAL_OFFER_FAILURE'

export const fetchSpecialOffer = () => dispatch => {
	dispatch({ type: FETCH_SPECIAL_OFFER_REQUEST })

	return verificationApi.fetchSpecialOffer()
		.then(response => {
			dispatch({ type: FETCH_SPECIAL_OFFER_SUCCESS, specialOffer: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_SPECIAL_OFFER_FAILURE, err })
			throw err
		})
}

export const ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_REQUEST = 'ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_REQUEST'
export const ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_SUCCESS = 'ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_SUCCESS'
export const ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_FAILURE = 'ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_FAILURE'

export const addListingSpecialOfferUserSubscriptions = specialOfferIds => (dispatch, getState, { session, resolve }) => {
	dispatch({ type: ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_REQUEST, specialOfferIds: specialOfferIds })

	return verificationApi
		.addListingSpecialOfferUserSubscriptions(specialOfferIds)
		.then(response => {
			dispatch({ type: ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_SUCCESS, specialOfferUserSubscriptions: response })

			window.location.href = RouteConstants.auth.specialOffersSubmitted.path

			return response
		})
		.catch(err => {
			dispatch({ type: ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_FAILURE, err })
			throw err
		})
}

export const FETCH_PROGRESS_REQUEST = 'FETCH_PROGRESS_REQUEST'
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS'
export const FETCH_PROGRESS_FAILURE = 'FETCH_PROGRESS_FAILURE'

export const fetchVerificationProgress = () => (dispatch, getState, { session, resolve }) => {
	dispatch({ type: FETCH_PROGRESS_REQUEST })
	let progressId = session.getSession().progressId

	if (!progressId) return resolve(null)
	return verificationApi
		.fetchProgress(progressId)
		.then(response => {
			dispatch({ type: FETCH_PROGRESS_SUCCESS, progress: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_PROGRESS_FAILURE, err })
			throw err
		})
}

export const requireVerificationProgress = () => (dispatch, getState, { resolve }) => {
	let progress = selectCurrentProgress(getState())
	if (progress && progress.id) return resolve(progress)
	return dispatch(fetchVerificationProgress())
}

export const requireVerificationRequestData = () => (dispatch, getState, { resolve }) => {
	return Promise.all([
		dispatch(requireVerificationProgress()),
		dispatch(requireOccupation()),
		dispatch(requirePrequalificationInfo()),
	])
		.then(values => {
			return {
				progress: values[0],
				occupation: values[1],
				prequal: values[2],
			}
		})
		.catch(err => {
			throw err
		})
}

export const FETCH_OCCUPATIONS_REQUEST = 'FETCH_OCCUPATIONS_REQUEST'
export const FETCH_OCCUPATIONS_SUCCESS = 'FETCH_OCCUPATIONS_SUCCESS'
export const FETCH_OCCUPATIONS_FAILURE = 'FETCH_OCCUPATIONS_FAILURE'

export const fetchOccupations = () => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_OCCUPATIONS_REQUEST })
	return verificationApi
		.fetchOccupations()
		.then(response => {
			const data = normalize(response, [schema.occupation])
			dispatch({ type: FETCH_OCCUPATIONS_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_OCCUPATIONS_FAILURE, err })
			throw err
		})
}

export const requireOccupations = () => (dispatch, getState, { resolve }) => {
	let state = getState()
	let occupations = selectOccupations(state)
	if (occupations.length > 0) return resolve(occupations)
	return dispatch(fetchOccupations())
}

export const requireOccupation = () => (dispatch, getState, { session, resolve }) => {
	return dispatch(requireOccupations()).then(() => {
		let occupation = selectCurrentOccupation(getState())
		if (occupation) return resolve(occupation)
		let occupationId = session.getSession().occupationId
		if (!occupationId) return resolve(null)
		dispatch({ type: SET_CURRENT_OCCUPATION, occupationId })
		return resolve(selectCurrentOccupation(getState()))
	})
}

export const SET_CURRENT_OCCUPATION = 'SET_CURRENT_OCCUPATION'

export const VALIDATE_OCCUPATION_REQUEST = 'VALIDATE_OCCUPATION_REQUEST'
export const VALIDATE_OCCUPATION_SUCCESS = 'VALIDATE_OCCUPATION_SUCCESS'
export const VALIDATE_OCCUPATION_FAILURE = 'VALIDATE_OCCUPATION_FAILURE'

export const validateOccupationCompatibility = (occupation, listing) => (dispatch, getState, { session, history }) => {
	dispatch({ type: VALIDATE_OCCUPATION_REQUEST })
	return verificationApi.validateOccupationCompatibility(occupation.id, listing.id)
		.then(response => {
			dispatch({ type: VALIDATE_OCCUPATION_SUCCESS, occupationCompatibility: response })
			//IF INCOMPATIBLE, REDIRECT TO /occupation-not-compatible
			if (!response.isCompatible) {
				history.push(RouteConstants.occupationNotCompatible.path)
				return response
			}
			//IF COMPATIBLE, CONTINUE VERIFICATION:
			return dispatch(saveOccupation(occupation, listing.id))
		})
		.catch(err => {
			dispatch({ type: VALIDATE_OCCUPATION_FAILURE, err })
			throw err
		})
}

export const SAVE_OCCUPATION_REQUEST = 'SAVE_OCCUPATION_REQUEST'
export const SAVE_OCCUPATION_SUCCESS = 'SAVE_OCCUPATION_SUCCESS'
export const SAVE_OCCUPATION_FAILURE = 'SAVE_OCCUPATION_FAILURE'

export const FETCH_PREQUALINFO_REQUEST = 'FETCH_PREQUALINFO_REQUEST'
export const FETCH_PREQUALINFO_SUCCESS = 'FETCH_PREQUALINFO_SUCCESS'
export const FETCH_PREQUALINFO_FAILURE = 'FETCH_PREQUALINFO_FAILURE'

export const saveOccupation = (occupation, listingId) => (dispatch, getState, { session, history }) => {
	let state = getState()
	let progress = { ...state.verification.progress, occupationId: occupation.id, listingId: listingId }
	dispatch({ type: SAVE_OCCUPATION_REQUEST })
	return dispatch(saveVerificationProgress(progress))
		.then(response => {
			dispatch({ type: SAVE_OCCUPATION_SUCCESS, occupationId: occupation.id })

			session.setSession({ occupationId: occupation.id })
			if (occupation && (!occupation.children || occupation.children.length === 0)) {
				history.push(RouteConstants.verification.prequal.path)
				return response
			}
			history.push(RouteConstants.verification.prequal.path)
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_OCCUPATION_FAILURE, err })
			throw err
		})
}

export const fetchPrequalificationInfo = occupationId => (dispatch, getState, {}) => {
	dispatch({ type: FETCH_PREQUALINFO_REQUEST })
	return verificationApi
		.fetchPrequalificationInfo(occupationId)
		.then(response => {
			dispatch({ type: FETCH_PREQUALINFO_SUCCESS, info: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_PREQUALINFO_FAILURE, err })
			throw err
		})
}

export const requirePrequalificationInfo = () => (dispatch, getState, { resolve, session }) => {
	let info = selectPrequal(getState())
	if (info) return resolve(info)

	let occupationId = session.getSession().occupationId
	if (!occupationId) return resolve(null)
	return dispatch(fetchPrequalificationInfo(occupationId))
}

export const SAVE_PREQUALIFIERS_REQUEST = 'SAVE_PREQUALIFIERS_REQUEST'
export const SAVE_PREQUALIFIERS_SUCCESS = 'SAVE_PREQUALIFIERS_SUCCESS'
export const SAVE_PREQUALIFIERS_FAILURE = 'SAVE_PREQUALIFIERS_FAILURE'

export const FETCH_ALLOWEDVERIFICATIONTYPES_REQUEST = 'FETCH_ALLOWEDVERIFICATIONTYPES_REQUEST'
export const FETCH_ALLOWEDVERIFICATIONTYPES_SUCCESS = 'FETCH_ALLOWEDVERIFICATIONTYPES_SUCCESS'
export const FETCH_ALLOWEDVERIFICATIONTYPES_FAILURE = 'FETCH_ALLOWEDVERIFICATIONTYPES_FAILURE'

export const savePrequalifications = (occupation, prequalifiers, listingId) => (dispatch, getState) => {
	return dispatch(requireVerificationProgress())
		.then(progress => {
			dispatch({ type: SAVE_PREQUALIFIERS_REQUEST }) //Needed to trigger event in GoogleAnalyticsMiddleware
			let updated = { ...progress, prequalifiers: prequalifiers, listingId: listingId }
			return dispatch(saveVerificationProgress(updated)).then(() => {
				return dispatch(fetchAllowedVerificationTypes(progress.id)).then(types => {
					return dispatch(handleAutoVerificationTypes(types))
				})
			})
		})
		.catch(err => {
			dispatch({ type: SAVE_PREQUALIFIERS_FAILURE, err })
			throw err
		})
}

export const handleAutoVerificationTypes = types => (dispatch, getState, { session, history }) => {
	if (types.find(t => t.autoRun && t.id == 1)) {
		let data = selectDataForAutoDmdcRequest(getState())
		return dispatch(autoProcessDMDC(data, types))
	} else if (types.find(t => t.autoRun && t.id == 7)) {
		let data = selectDataForCreateRequest(getState())
		return dispatch(autoProcessAuto(data, types))
	} else if (types.find(t => t.autoRun && t.id == 8)) {
		let data = selectDataForAutoDmdcFamilyRequest(getState())
		return dispatch(autoProcessDMDCFamily(data, types))
	}

	let typesWithoutDmdc = types.filter(t => t.id != 1 && t.Id != 8)

	if (typesWithoutDmdc.length == 1) {
		switch (typesWithoutDmdc[0].id) {
			case 3:
				return history.push(RouteConstants.verification.verifyEmail.path)
			case 4:
				let info = getState().verification.info
				if (info
					&& info.verificationDocuments
					&& info.verificationDocuments.length === 1) {
					let doc =  info.verificationDocuments[0]

					if (doc.subTypes.length === 0)
						return history.push(`/verify/doc/upload?type=${ doc.verificationDocumentType }&docUploadStep=upload`)
					return history.push(`/verify/doc/${ doc.verificationDocumentType }`)
				}

				return history.push(RouteConstants.verification.verifyDoc.index.path)
		}
	}

	return history.push(RouteConstants.verification.types.path)
}

export const fetchAllowedVerificationTypes = progressId => (dispatch, getState, {}) => {
	return verificationApi
		.fetchAllowedVerificationTypes(progressId)
		.then(response => {
			dispatch({ type: FETCH_ALLOWEDVERIFICATIONTYPES_SUCCESS, types: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_ALLOWEDVERIFICATIONTYPES_FAILURE, err })
			throw err
		})
}

export const SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST = 'SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST'
export const SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS = 'SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS'
export const SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE = 'SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE'

export const saveDmdcFamilyAutoDocumentVerificationRequest = formData => (dispatch, getState, { session }) => {
	dispatch({ type: SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST })
	dispatch(setProcessingMessage('Please wait while we verify your document, this may take several minutes.'))
	return verificationApi
		.saveDmdcFamilyAutoDocumentVerificationRequest(formData)
		.then(response => {
			dispatch({ type: SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE, err })
			dispatch(endProcessing())
			throw err
		})
}

export const SAVE_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST = 'SAVE_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST'
export const SAVE_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS = 'SAVE_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS'
export const SAVE_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE = 'SAVE_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE'

export const saveAutoDocumentVerificationRequest = formData => (dispatch, getState, { session }) => {
	dispatch({ type: SAVE_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST })
	dispatch(setProcessingMessage('Please wait while we verify your document, this may take several minutes.'))
	return verificationApi
		.saveAutoDocumentVerificationRequest(formData)
		.then(response => {
			dispatch({ type: SAVE_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE, err })
			dispatch(endProcessing())
			throw err
		})
}

export const SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_REQUEST = 'SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_REQUEST'
export const SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_SUCCESS = 'SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_SUCCESS'
export const SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_FAILURE = 'SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_FAILURE'

export const saveManualDocumentVerificationRequest = request => (dispatch, getState, { session }) => {
	dispatch({ type: SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_REQUEST })
	return verificationApi
		.saveManualDocumentVerificationRequest(request)
		.then(response => {
			dispatch({ type: SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_FAILURE, err })
			throw err
		})
}

export const SAVE_EMAILVERIFICATIONREQUEST_REQUEST = 'SAVE_EMAILVERIFICATIONREQUEST_REQUEST'
export const SAVE_EMAILVERIFICATIONREQUEST_SUCCESS = 'SAVE_EMAILVERIFICATIONREQUEST_SUCCESS'
export const SAVE_EMAILVERIFICATIONREQUEST_FAILURE = 'SAVE_EMAILVERIFICATIONREQUEST_FAILURE'

export const saveEmailVerificationRequest = request => (dispatch, getState, { session, history }) => {
	dispatch({ type: SAVE_EMAILVERIFICATIONREQUEST_REQUEST })
	return verificationApi
		.saveEmailVerificationRequest(request)
		.then(response => {
			dispatch({ type: SAVE_EMAILVERIFICATIONREQUEST_SUCCESS, verificationRequest: response })
			session.setSession({ requestId: response.id })
			if (response.status == 'Failed') return response
			else if (response.status == 'Approved') oauthRedirect()
			else if (response.status == 'Pending' && response.authorizationMethod == 'EmailLink')
				history.push(RouteConstants.verification.activationCode.path)
			else oauthRedirect()

			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_EMAILVERIFICATIONREQUEST_FAILURE, err })
			throw err
		})
}

export const SAVE_VAVERIFICATIONREQUEST_REQUEST = 'SAVE_VAVERIFICATIONREQUEST_REQUEST'
export const SAVE_VAVERIFICATIONREQUEST_SUCCESS = 'SAVE_VAVERIFICATIONREQUEST_SUCCESS'
export const SAVE_VAVERIFICATIONREQUEST_FAILURE = 'SAVE_VAVERIFICATIONREQUEST_FAILURE'

export const saveVaVerificationRequest = request => (dispatch, getState, { session, history }) => {
	dispatch({ type: SAVE_VAVERIFICATIONREQUEST_REQUEST })
	dispatch(setProcessingMessage('Please stand by. This process can take up to a minute.'))
	return verificationApi
		.saveVaVerificationRequest(request)
		.then(response => {
			dispatch({ type: SAVE_VAVERIFICATIONREQUEST_SUCCESS, verificationRequest: response })
			session.setSession({ requestId: response.id })
			if (response.status == 'Approved')
				oauthRedirect()
			else
				history.push(RouteConstants.verification.verifyVaLookupFailed.path)

			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_VAVERIFICATIONREQUEST_FAILURE, err })
			dispatch(endProcessing())
			throw err
		})
}

export const AUTO_PROCESS_AUTO_REQUEST = 'AUTO_PROCESS_AUTO_REQUEST'
export const AUTO_PROCESS_AUTO_SUCCESS = 'AUTO_PROCESS_AUTO_SUCCESS'
export const AUTO_PROCESS_AUTO_FAILURE = 'AUTO_PROCESS_AUTO_FAILURE'

export const autoProcessAuto = (request, allowedTypes) => (dispatch, getState, { session }) => {
	dispatch({ type: AUTO_PROCESS_AUTO_REQUEST })
	dispatch(setProcessingMessage('Please stand by. This process can take up to a minute.'))
	return verificationApi
		.autoProcessAuto(request)
		.then(response => {
			dispatch({ type: AUTO_PROCESS_AUTO_SUCCESS, verificationRequest: response })
			session.setSession({ requestId: response.id })

			oauthRedirect()
			return
		})
		.catch(err => {
			dispatch({ type: AUTO_PROCESS_AUTO_FAILURE, err })
			dispatch(endProcessing())

			return err
		})
}

export const AUTO_PROCESS_DMDC_REQUEST = 'AUTO_PROCESS_DMDC_REQUEST'
export const AUTO_PROCESS_DMDC_SUCCESS = 'AUTO_PROCESS_DMDC_SUCCESS'
export const AUTO_PROCESS_DMDC_FAILURE = 'AUTO_PROCESS_DMDC_FAILURE'

export const autoProcessDMDC = (request, allowedTypes) => (dispatch, getState, { session, history }) => {
	dispatch({ type: AUTO_PROCESS_DMDC_REQUEST })
	dispatch(setProcessingMessage('Please stand by. This process can take up to a minute.'))
	return verificationApi
		.autoProcessDMDC(request)
		.then(response => {
			dispatch({ type: AUTO_PROCESS_DMDC_SUCCESS, verificationRequest: response })
			session.setSession({ requestId: response.id })

			if (response.status == 'Approved') {
				oauthRedirect()
				return
			} else {
				var isHardRejected = response.authorizationMethod === 'DmdcDuplicateHardRejection'
				if (isHardRejected) {
					history.push(RouteConstants.duplicateAccount.path)
				} else {
					let typesWithoutDmdc = allowedTypes.filter(t => t.id != 1)
					if (typesWithoutDmdc.length == 1) {
						switch (typesWithoutDmdc[0].id) {
							case 3:
								history.push(RouteConstants.verification.verifyEmail.path)
							case 4:
								history.push(RouteConstants.verification.verifyDoc.index.path)
						}
					} else {
						history.push(RouteConstants.verification.types.path)
					}
				}
			}

			dispatch(endProcessing())
			return response
		})
		.catch(err => {
			dispatch({ type: AUTO_PROCESS_DMDC_FAILURE, err })
			dispatch(endProcessing())

			let typesWithoutDmdc = allowedTypes.filter(t => t.id != 1)
			if (typesWithoutDmdc.length == 1) {
				switch (typesWithoutDmdc[0].id) {
					case 3:
						history.push(RouteConstants.verification.verifyEmail.path)

					case 4:
						history.push(RouteConstants.verification.verifyDoc.index.path)
				}
			} else {
				history.push(RouteConstants.verification.types.path)
			}

			return err // Typically we would re-throw the error.
		})
}

export const MANUAL_PROCESS_DMDC_REQUEST = 'MANUAL_PROCESS_DMDC_REQUEST'
export const MANUAL_PROCESS_DMDC_SUCCESS = 'MANUAL_PROCESS_DMDC_SUCCESS'
export const MANUAL_PROCESS_DMDC_FAILURE = 'MANUAL_PROCESS_DMDC_FAILURE'

export const manualProcessDMDC = request => (dispatch, getState, { session }) => {
	dispatch({ type: MANUAL_PROCESS_DMDC_REQUEST })
	return verificationApi
		.manualProcessDMDC(request)
		.then(response => {
			dispatch({ type: MANUAL_PROCESS_DMDC_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: MANUAL_PROCESS_DMDC_FAILURE, err })
			throw err
		})
}

export const AUTO_PROCESS_DMDC_FAMILY_REQUEST = 'AUTO_PROCESS_DMDC_FAMILY_REQUEST'
export const AUTO_PROCESS_DMDC_FAMILY_SUCCESS = 'AUTO_PROCESS_DMDC_FAMILY_SUCCESS'
export const AUTO_PROCESS_DMDC_FAMILY_FAILURE = 'AUTO_PROCESS_DMDC_FAMILY_FAILURE'

export const autoProcessDMDCFamily = (request, allowedTypes) => (dispatch, getState, { session, history }) => {
	dispatch({ type: AUTO_PROCESS_DMDC_FAMILY_REQUEST })
	dispatch(setProcessingMessage('Please stand by. This process can take up to a minute.'))
	return verificationApi
		.autoProcessDMDCFamily(request)
		.then(response => {
			dispatch({ type: AUTO_PROCESS_DMDC_FAMILY_SUCCESS, verificationRequest: response })
			session.setSession({ requestId: response.id })

			if (response.status == 'Partial') {
				history.push(RouteConstants.verification.verifyFamilyDocUpload.path)
			} else {
				let typesWithoutDmdc = allowedTypes.filter(t => t.id != 1 && t.id != 8)
				if (typesWithoutDmdc.length == 1) {
					switch (typesWithoutDmdc[0].id) {
						case 3:
							history.push(RouteConstants.verification.verifyEmail.path)
						case 4:
							history.push(RouteConstants.verification.verifyDoc.index.path)
					}
				} else {
					history.push(RouteConstants.verification.types.path)
				}
			}

			dispatch(endProcessing())
			return response
		})
		.catch(err => {
			dispatch({ type: AUTO_PROCESS_DMDC_FAMILY_FAILURE, err })
			dispatch(endProcessing())

			let typesWithoutDmdc = allowedTypes.filter(t => t.id != 1 && t.id != 8)
			if (typesWithoutDmdc.length == 1) {
				switch (typesWithoutDmdc[0].id) {
					case 3:
						history.push(RouteConstants.verification.verifyEmail.path)

					case 4:
						history.push(RouteConstants.verification.verifyDoc.index.path)
				}
			} else {
				history.push(RouteConstants.verification.types.path)
			}

			return err // Typically we would re-throw the error.
		})
}

export const MANUAL_PROCESS_DMDC_FAMILY_REQUEST = 'MANUAL_PROCESS_DMDC_FAMILY_REQUEST'
export const MANUAL_PROCESS_DMDC_FAMILY_SUCCESS = 'MANUAL_PROCESS_DMDC_FAMILY_SUCCESS'
export const MANUAL_PROCESS_DMDC_FAMILY_FAILURE = 'MANUAL_PROCESS_DMDC_FAMILY_FAILURE'

export const manualProcessDMDCFamily = request => (dispatch, getState, { session }) => {
	dispatch({ type: MANUAL_PROCESS_DMDC_FAMILY_REQUEST })
	return verificationApi
		.manualProcessDMDCFamily(request)
		.then(response => {
			dispatch({ type: MANUAL_PROCESS_DMDC_FAMILY_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: MANUAL_PROCESS_DMDC_FAMILY_FAILURE, err })
			throw err
		})
}

export const SAVE_PHONEVERIFICATIONREQUEST_REQUEST = 'SAVE_PHONEVERIFICATIONREQUEST_REQUEST'
export const SAVE_PHONEVERIFICATIONREQUEST_SUCCESS = 'SAVE_PHONEVERIFICATIONREQUEST_SUCCESS'
export const SAVE_PHONEVERIFICATIONREQUEST_FAILURE = 'SAVE_PHONEVERIFICATIONREQUEST_FAILURE'

export const savePhoneVerificationRequest = request => (dispatch, getState, { session }) => {
	dispatch({ type: SAVE_PHONEVERIFICATIONREQUEST_REQUEST })
	return verificationApi
		.savePhoneVerificationRequest(request)
		.then(response => {
			dispatch({ type: SAVE_PHONEVERIFICATIONREQUEST_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_PHONEVERIFICATIONREQUEST_FAILURE, err })
			throw err
		})
}

export const SAVE_ACTIVATIONCODE_REQUEST = 'SAVE_ACTIVATIONCODE_REQUEST'
export const SAVE_ACTIVATIONCODE_SUCCESS = 'SAVE_ACTIVATIONCODE_SUCCESS'
export const SAVE_ACTIVATIONCODE_FAILURE = 'SAVE_ACTIVATIONCODE_FAILURE'

export const saveActivationCode = code => (dispatch, getState, { session }) => {
	dispatch({ type: SAVE_ACTIVATIONCODE_REQUEST })

	return verificationApi
		.saveActivationCode(code)
		.then(response => {
			dispatch({ type: SAVE_ACTIVATIONCODE_SUCCESS, verificationRequest: response })
			verificationRequestSaved(response, session)
			return response
		})
		.catch(err => {
			dispatch({ type: SAVE_ACTIVATIONCODE_FAILURE, err })
			throw err
		})
}

export const FETCH_VERIFICATION_REQUEST = 'FETCH_VERIFICATION_REQUEST'
export const FETCH_VERIFICATION_SUCCESS = 'FETCH_VERIFICATION_SUCCESS'
export const FETCH_VERIFICATION_FAILURE = 'FETCH_VERIFICATION_FAILURE'

export const fetchVerificationRequest = () => (dispatch, getState, { session, resolve }) => {
	let id = session.getSession().requestId
	if (!id) return resolve(null)

	dispatch({ type: FETCH_VERIFICATION_REQUEST })
	return verificationApi
		.fetchVerificationRequest(id)
		.then(response => {
			dispatch({ type: FETCH_VERIFICATION_SUCCESS, verificationRequest: response })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_VERIFICATION_REQUEST, err })
			throw err
		})
}

export const verificationRequestSaved = (request, session) => {
	session.setSession({ requestId: request.id })
	oauthRedirect()
}

export const requireVerificationRequest = () => (dispatch, getState, { session, resolve }) => {
	let request = selectRequest(getState())
	if (request) return resolve(request)

	return dispatch(fetchVerificationRequest())
}

export const GOTO_VERIFICATION_TYPE = 'GOTO_VERIFICATION_TYPE'

export const goToVerificationType = verificationType => (dispatch, getState, { session, history }) => {
	dispatch({ type: GOTO_VERIFICATION_TYPE, verificationType })
	return history.push(RouteConstants.verification.types.path)
}

//reducer

export const STATE_KEY = 'verification'

export const DEFAULT_STATE = {
	occupations: { db: {}, hierarchy: [], currentId: 0 },
	occupation: null,
	occupationCompatibility: null,
	error: null,
	prequal: null,
	info: null,
	types: {
		manual: [],
		autoSelect: 0,
	},
	type: null,
	progress: null,
	request: null,
	processingDoc: false,
	advertisement: '',
	specialOffer: null
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch (action.type) {
		case FETCH_ADVERTISEMENT_SUCCESS:
			return { ...state, advertisement: action.advertisement }
		case FETCH_OCCUPATIONS_SUCCESS:
			return { ...state, occupations: { db: action.data.entities.occupations, hierarchy: action.data.result } }
		case FETCH_OCCUPATIONS_FAILURE:
			return { ...state, error: action.err }
		case VALIDATE_OCCUPATION_SUCCESS:
			return { ...state, occupationCompatibility: action.occupationCompatibility }
		case VALIDATE_OCCUPATION_FAILURE:
			return { ...state, error: action.err }
		case SAVE_OCCUPATION_SUCCESS:
			return { ...state, occupations: { ...state.occupations, currentId: action.occupationId } }
		case SAVE_PROGRESS_SUCCESS:
			return { ...state, progress: action.progress }
		case FETCH_PROGRESS_SUCCESS:
			return { ...state, progress: action.progress }
		case SET_CURRENT_OCCUPATION:
			return { ...state, occupations: { ...state.occupations, currentId: action.occupationId } }
		case SAVE_OCCUPATION_FAILURE:
			return { ...state, error: action.err }
		case FETCH_PREQUALINFO_SUCCESS:
			return { ...state, info: action.info }
		case FETCH_PREQUALINFO_FAILURE:
			return { ...state, error: action.err }
		case SAVE_PREQUALIFIERS_FAILURE:
			return { ...state, error: action.err }
		case FETCH_ALLOWEDVERIFICATIONTYPES_REQUEST:
			return { ...state }
		case FETCH_ALLOWEDVERIFICATIONTYPES_SUCCESS:
			return { ...state, types: { ...state.types, manual: action.types } }
		case FETCH_ALLOWEDVERIFICATIONTYPES_FAILURE:
			return { ...state }
		case SAVE_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST:
		case SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST:
			return { ...state, processingDoc: true }
		case SAVE_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS:
		case SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS:
			return { ...state, processingDoc: false, request: action.verificationRequest }
		case SAVE_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE:
		case SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_FAILURE:
			return { ...state, processingDoc: false, error: action.err }
		case SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_REQUEST:
			return { ...state }
		case SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_FAILURE:
			return { ...state, error: action.err }
		case SAVE_EMAILVERIFICATIONREQUEST_REQUEST:
			return { ...state }
		case SAVE_EMAILVERIFICATIONREQUEST_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case SAVE_EMAILVERIFICATIONREQUEST_FAILURE:
			return { ...state, error: action.err }
		case AUTO_PROCESS_DMDC_REQUEST:
			return { ...state }
		case AUTO_PROCESS_DMDC_SUCCESS:
			if (action.verificationRequest.status == 'Approved')
				return { ...state, request: { ...state.request, ...action.verificationRequest } }
			else {
				var isUnavailable = action.verificationRequest.authMetaData && action.verificationRequest.authMetaData['dmdc-authorization-status'] === 'Unavailable'
				var manual = state.types.manual.filter(
					x =>
						isUnavailable ||
						x.id != 1
				)
				return {
					...state,
					types: { ...state.types, manual: manual, autoSelect: 1 },
					request: { ...state.request, ...action.verificationRequest },
				}
			}
		case AUTO_PROCESS_DMDC_FAILURE:
			var manual = state.types.manual.filter(x => action.err.code === 'dmdc_not_available' || x.id != 1)
			return { ...state, types: { ...state.types, manual: manual, autoSelect: 1 } }
		case MANUAL_PROCESS_DMDC_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case MANUAL_PROCESS_DMDC_FAILURE:
			return { ...state, error: action.err }
		case AUTO_PROCESS_DMDC_FAMILY_REQUEST:
			return { ...state }
		case AUTO_PROCESS_DMDC_FAMILY_SUCCESS:
			if (action.verificationRequest.status == 'Approved')
				return { ...state, request: { ...state.request, ...action.verificationRequest } }
			else {
				var isUnavailable = action.verificationRequest.authMetaData && action.verificationRequest.authMetaData['dmdc-authorization-status'] === 'Unavailable'
				var manual = state.types.manual.filter(
					x =>
						isUnavailable ||
						x.id != 1
				)
				return {
					...state,
					types: { ...state.types, manual: manual, autoSelect: 1 },
					request: { ...state.request, ...action.verificationRequest },
				}
			}
		case AUTO_PROCESS_DMDC_FAMILY_FAILURE:
			var manual = state.types.manual.filter(x => action.err.code === 'dmdc_not_available' || x.id != 1)
			return { ...state, types: { ...state.types, manual: manual, autoSelect: 1 } }
		case MANUAL_PROCESS_DMDC_FAMILY_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case MANUAL_PROCESS_DMDC_FAMILY_FAILURE:
			return { ...state, error: action.err }
		case SAVE_PHONEVERIFICATIONREQUEST_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case SAVE_VAVERIFICATIONREQUEST_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case SAVE_VAVERIFICATIONREQUEST_FAILURE:
			return { ...state, error: action.err }
		case FETCH_VERIFICATION_SUCCESS:
			return { ...state, request: action.verificationRequest }
		case SAVE_PHONEVERIFICATIONREQUEST_FAILURE:
			return { ...state, error: action.err }
		case WAITING_FOR_PHONECALL:
			return { ...state, phoneRedirect: action.redirect }
		case GOTO_VERIFICATION_TYPE:
			return { ...state, types: { ...state.types, autoSelect: action.verificationType } }
		case FETCH_SPECIAL_OFFER_REQUEST:
		case FETCH_SPECIAL_OFFER_FAILURE:
				return { ...state, specialOffer: null }
		case FETCH_SPECIAL_OFFER_SUCCESS:
				return {...state, specialOffer: action.specialOffer }
		default:
			return state
	}
}

export default reducer

//selectors

//occupations

const _selectOccupationsHashMap = state => state.verification.occupations.db
const _selectOccupationsHierarchy = state => state.verification.occupations.hierarchy

export const selectOccupations = createSelector(_selectOccupationsHashMap, occupations =>
	Object.keys(occupations).map(k => occupations[k])
)

export const selectOccupationHierarchy = createSelector(
	[_selectOccupationsHierarchy, _selectOccupationsHashMap],
	(hierarchy, occupations) => {
		let results = denormalize(hierarchy, [occupation], { occupations })

		return results
	}
)

export const selectOccupation = id => state => state.verification.occupations.db[id]

export const selectCurrentOccupation = state =>
	state.verification.occupations.db[state.verification.occupations.currentId]

export const selectRequest = state => state.verification.request

export const selectPhoneRedirect = state => state.verification.phoneRedirect

export const selectGovtDepartments = state => (state.verification.info ? state.verification.info.departments : [])

export const selectCurrentProgress = state => state.verification.progress

export const selectAvailableVerificationTypes = state => state.verification.types

export const selectManualVerificationTypes = createSelector(selectAvailableVerificationTypes, types => {
	if (!types || !types.manual) return []

	return types.manual.filter(t => t.id !== 1)
})

export const isProcessingDocument = state => state.verification.processingDoc

export const selectVerificationInfo = state => state.verification.info

export const selectOccupationCompatibility = state => state.verification.occupationCompatibility

export const selectDuplicateAccount = state => state.verification.duplicateAccount

//export const selectOccupation = state => state.verification.occupation

//export const selectOccupations = state => state.verification.occupations

export const selectPrequalifiers = state => (state.verification.info ? state.verification.info.qualifiers : [])

export const selectAdvertisement = state => state.verification.advertisement

export const selectPrequalifierSocialProof = state =>
	state.verification.info ? state.verification.info.prequalifierSocialProof : null

export const selectVerificationMessage = state =>
	state.verification.info ? state.verification.info.verificationMessage : null

export const selectPrequal = state => state.verification.info

export const selectVerificationError = state => state.verification.error

const safeGet = (array, key) => {
	if (typeof array === 'undefined' || !array || !array.find(x => x.key === key)) return null
	return array.find(x => x.key === key).value
}

export const selectDataForCreateRequest = createSelector(
	[selectCurrentProgress, selectCurrentOccupation],
	(progress, occupation) => {
		if (!progress || !occupation) return null

		return {
			firstName: safeGet(progress.prequalifiers, 'first-name'),
			lastName: safeGet(progress.prequalifiers, 'last-name'),
			occupationId: occupation.id,
			metaData: [
				{ key: 'govt-dept', value: safeGet(progress.prequalifiers, 'govt-dept') },
				{ key: 'academic-institution', value: safeGet(progress.prequalifiers, 'academic-institution') },
				{ key: 'gold-star-family-member', value: safeGet(progress.prequalifiers, 'gold-star-family-member') },
				{ key: 'last-year-of-service', value: safeGet(progress.prequalifiers, 'last-year-of-service') },
				{ key: 'npi-number', value: safeGet(progress.prequalifiers, 'npi-number') },
			],
		}
	}
)

export const selectDataForAutoDmdcRequest = createSelector(
	[selectDataForCreateRequest, selectCurrentProgress],
	(baseData, progress) => {
		if (!baseData) return null

		return {
			...baseData,
			dateOfBirth: DateMask.mask(safeGet(progress.prequalifiers, 'dob')),
			lastYearOfService: safeGet(progress.prequalifiers, 'last-year-of-service'),
		}
	}
)

export const selectDataForAutoDmdcFamilyRequest = createSelector(
	[selectDataForCreateRequest, selectCurrentProgress],
	(baseData, progress) => {
		if (!baseData) return null

		return {
			...baseData,
			serviceMemberFirstName: safeGet(progress.prequalifiers, 'service-member-first-name'),
			serviceMemberLastName: safeGet(progress.prequalifiers, 'service-member-last-name'),
			serviceMemberDateOfBirth: DateMask.mask(safeGet(progress.prequalifiers, 'service-member-dob')),
			serviceMemberLastYearOfService: safeGet(progress.prequalifiers, 'service-member-last-year-of-service'),
		}
	}
)

export const selectSpecialOffer = state => state.verification.specialOffer