import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
	header: {
		padding: '0px 30px',
		height: props => `${props.settings.logoHeight + 30}px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'@media (max-width: 574px)': {
			backgroundColor: props => props.settings.mobileBackgroundColor,
			justifyContent: 'center',
		},
		'@media (min-width: 575px)': {
			position: 'absolute',
			top: '0px',
			right: '0px',
			left: '0px',
			backgroundColor: props => props.settings.backgroundType === 'transparent' ? 'transparent' : props.settings.backgroundColor,
			justifyContent: props => props.settings.logoAlignment == 'left' ? 'flex-start' : 'center',
		},
	},
	logo: {
		maxWidth: '250px',
		maxHeight: props => props.settings.logoHeight,
	},
}

export const PrivateLabelHeader = ({ classes, settings, listingName }) => (
	<div className={ classes.header }>
		<img src={ settings.logoImageUrl } alt={ listingName } className={ classes.logo } />
	</div>
)

PrivateLabelHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	listingName: PropTypes.string.isRequired,
}

export default injectSheet(styles)(PrivateLabelHeader)