import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from 'recompose'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import { withStyles } from "@mui/styles";
import { hideModal } from "../../../redux/actions";

const styles = {
	paperWidthSm: {
		width: '100%',
		maxWidth: props => props.maxWidth,
		padding: '40px',
		borderRadius: '3px'
	},
	title: {
		fontFamily: 'geomanist-medium, Helvetica, sans-serif',
		fontSize: '24px',
		padding: '0px 0px 10px 0px',
    fontWeight: 'bold !important'
	}
}

export const ModalContainer = ({
	children,
	classes,
	hideModal,
	title,
	...rest
}) => (
	<Dialog open={ true } onClose={ hideModal } classes={{ paperWidthSm: classes.paperWidthSm }}>
		{ title && <DialogTitle className={classes.title}>{ title }</DialogTitle> }
		{ children }
	</Dialog>
)

ModalContainer.propTypes = {
	hideModal: PropTypes.func.isRequired,
	title: PropTypes.string,
	children: PropTypes.node,
	classes: PropTypes.object.isRequired,
	maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal())
})

const enhance = compose(
	connect(null, mapDispatchToProps),
	withStyles(styles),
)

export default enhance(ModalContainer)