import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../setup/route-constants'
import { isLoggedIn } from '../../redux/selectors'
import { requireUser, requireVerificationRequestData } from '../../redux/actions'

const LoadableWelcomeScreen = Loadable({
	loader: () => import(/* webpackChunkName: "welcome" */ './'),
	loading: () => null
})

export default {
	name: 'welcome',
	path: RouteConstants.register.welcome.path,
	page: true,
	component: LoadableWelcomeScreen,
	lifecycle: {
		authorize: (match, location, { getState, dispatch }, history) => {
			return dispatch(requireUser()).then(response => {
				if (!isLoggedIn(getState())) {
					history.replace({ pathname: '/login', search: `?returnUrl=${location.pathname}` })
					return { authorized: false }
				}
				return { authorized: true }
			})
		},
		fetch: (match, location, { dispatch }) => {
			let verificationRequestDataPromise = dispatch(requireVerificationRequestData())
			return Promise.all([verificationRequestDataPromise])
		},
	},
	config: () => ({

	})
}