import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../setup/route-constants'

const LoadableLoginComponent = Loadable({
	loader: () => import(/* webpackChunkName: "login" */ './'),
	loading: () => null
})

export default {
	name: 'login',
	path: RouteConstants.login.index.path,
	page: true,
	component: LoadableLoginComponent,
	lifecycle: {
		// authorize: (match, location, { getState }) => {
		// 	var user = selectUser(getState())
		// 	return { authorized : !!user && user.isApproved, redirect: '/' }
		// }
	},
	config: () => ({

	})
}