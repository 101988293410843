import { COMPLETE_REGISTRATION_SUCCESS } from '../redux/modules/users'
import { selectUser } from '../redux/selectors'

let _lastKnownUser = {}

export const inspectletMiddleware = store => next => action => {
	let result = next(action)
	let state = store.getState()
	window.__insp = window.__insp || []

	let user = selectUser(state)
	if (user != _lastKnownUser) {
			if (user)
				window.__insp.push(['identify', user.email])
			else
				window.__insp.push(['identify', ''])
			_lastKnownUser = user
	}

	switch (action.type) {
		case COMPLETE_REGISTRATION_SUCCESS:
			window.__insp.push(['tagSession', 'complete reg'])
			break

		default:
			break
	}
	return result
}

export default inspectletMiddleware