import React from "react";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { clearNotification } from "../../redux/actions";
import { selectNotification } from "../../redux/selectors";

export const GlobalNotification = ({ message, showing, clearNotification }) => (
  <Snackbar
    open={showing}
    message={message || ""}
    onClick={clearNotification}
    onClose={clearNotification}
    autoHideDuration={5000}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  />
);

const mapStateToProps = (state, ownProps) => selectNotification(state);

const mapDispatchToProps = (dispatch) => ({
  clearNotification: () => dispatch(clearNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotification);
