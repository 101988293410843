import { getJson, postJson } from './baseApi'

export const registerEmail = email => {
	return postJson('/api/register', {email})
}

export const completeRegistration = values => {
	return postJson('/api/register/completeRegistration', values)
}

export const getCurrentUser = () => {
	return getJson('/api/users/current')
}

export const login = data => {
	return postJson('/api/login', data)
		.catch(err => {
			if (err.response && err.response.status === 404)
				throw { email: "Hmm, we don't recognize that email. Please try again." }
			throw err
		})
}

export const forgotPassword = email => {
	return postJson('/api/forgot-password', {email})
}

export const resetPassword = model => {
	return postJson('/api/reset-password', model)
}

export const changePassword = model => {
	return postJson('/api/change-password', model)
}

export const confirmEmailCode = code => {
	return postJson('/api/confirm-email', {code})
}

export const sendVerificationEmail = () => {
	return postJson('/api/send-verification-email')
}
