import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import GovXLogo from '../GovXLogo'

const styles = {
	header: {
		'@media (max-width: 574px)': {
			height: '55px',
			textAlign: 'center',
			paddingTop: '15px',
			backgroundColor: '#2a2d36',
		},
		'@media (min-width: 575px)': {
			height: '70px',
			position: 'absolute',
			top: '0px',
			right: '0px',
			left: '0px',
			paddingTop: '30px',
			paddingLeft: props => props.settings.logoAlignment === 'left' ? '30px' : '0px',
			backgroundColor: 'transparent',
			textAlign: props => props.settings.logoAlignment,
		},
	},
}

export const GovXHeader = ({ classes, settings }) => (
	<div className={ classes.header }>
		<GovXLogo settings={ settings } isGovXListing={ true } isCoBrand={ false } />
	</div>
)

GovXHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
}

export default injectSheet(styles)(GovXHeader)