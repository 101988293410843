import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../setup/route-constants'
import { isLoggedIn, isEmailConfirmed, hasPassword } from '../../redux/selectors'
import { requireUser } from '../../redux/actions'

const LoadableVerifyOccupationPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "verification.occupation" */ './'),
	loading: () => null,
})

export default {
	name: 'verification.occupation',
	path: RouteConstants.verification.occupation.path + '*',
	page: true,
	component: LoadableVerifyOccupationPageContainer,
	lifecycle: {
		authorize: (match, location, { getState, dispatch }, history) => {
			return dispatch(requireUser()).then(response => {
				if (!isLoggedIn(getState())) {
					history.replace({ pathname: '/login', search: `?returnUrl=${location.pathname}` })
					return { authorized: false }
				}
				if (!isEmailConfirmed(getState()) && !hasPassword(getState())) {
					history.replace(RouteConstants.confirmEmail.path)
				}
				return { authorized: true }
			})
		},
	},
	config: () => ({}),
}
