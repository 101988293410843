import GovXGA from '../analytics/GovXGA'
import { getListing } from '../setup/configuration'
import { Util } from '@microsoft/applicationinsights-web'
import { selectCurrentOccupation, selectUser } from '../redux/selectors'
export const LOCATION_CHANGE = 'LOCATION_CHANGE'

import {
	REGISTER_EMAIL_REQUEST,
	REGISTER_EMAIL_SUCCESS,
	LOGIN_REQUEST,
	GOTO_FORGOT_PASSWORD_PAGE,
	GOTO_FORGOT_PASSWORD_PAGE_HARDREJECTION,
	GOTO_LOGIN_PAGE,
	GOTO_LOGIN_PAGE_HARDREJECTION,
	FORGOT_PASSWORD_REQUEST,
	RESET_PASSWORD_REQUEST,
	COMPLETE_REGISTRATION_REQUEST,
	CONFIRM_EMAIL_CODE_REQUEST,
	RESEND_VERIFICATION_EMAIL_REQUEST
} from '../redux/modules/users'

import {
	SAVE_PREQUALIFIERS_REQUEST,
	SAVE_EMAILVERIFICATIONREQUEST_REQUEST,
	SAVE_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST,
	SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST,
	MANUAL_PROCESS_DMDC_REQUEST,
	MANUAL_PROCESS_DMDC_FAMILY_REQUEST,
	SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_REQUEST,
	SAVE_PHONEVERIFICATIONREQUEST_REQUEST,
	selectRequest,
	ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_REQUEST,
	AUTO_PROCESS_AUTO_SUCCESS,
	SAVE_EMAILVERIFICATIONREQUEST_SUCCESS,
	AUTO_PROCESS_DMDC_SUCCESS,
	AUTO_PROCESS_DMDC_FAMILY_SUCCESS,
	SAVE_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS,
	SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS,
	SAVE_VAVERIFICATIONREQUEST_SUCCESS
} from '../redux/modules/verification'

export const GACategories = {
	verification: 'Verification',
	registerThankYouPage: 'Register Thank You Page',
}

export const GAActions = {
	click: 'click',
	clicked: 'clicked',
	open: 'open',
	hover: 'hover',
	signUpWithEmail: 'Sign Up With Email',
	login: 'login',
	forgotPW: 'Forgot PW',
	requestPWReset: 'Request PW Reset',
	resetPW: 'Reset PW',
	submitAffiliation: 'Submit Affiliation',
	dmdcRetry: 'DMDC Retry',
	emailVerification: 'Email Verification',
	docVerification: 'Doc Verification',
	dmdcFamilyDocVerification: 'DMDC Family Doc Verification',
	registeredApproved: 'Registered - Approved',
	registeredPending: 'Registered - Pending',
	registeredPendingPhone: 'Registered - Pending (Phone)',
	specialOfferSubscriptionsSubmitted: 'Special Offer Subscription',
	emailConfirmationCodeSubmitted: 'Confirm Email',
	resendActivationEmail: 'resend-activation-email',
	goToLogin: 'Go To Login',
	hardRejectionLogin: 'Hard Rejection Login',
	hardRejectionPWReset: 'Hard Rejection PW Reset'
}

const getTrackingEventLabel = (listing) => {
	if (listing) {
		let name = `${listing.name} (${listing.id})`
		if (listing.campaignId) {
			name += ` / ${listing.campaignName} (${listing.campaignId})`
		}
		return name
	}
	else
		return null
}

export const sendGAEvent = (category, action, label, user) => {
	//GovXGA.event({category, action, label})
	window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: action,
        click_track: {
			event_category: category,
			event_label: label
		}
    })
}

// Push Events to the common GovX DataLayer object
export const sendDataLayerEvent = (action, state) => {
    window.dataLayer = window.dataLayer || []
	let listing = getListing()
	let request = selectRequest(state)

	window.dataLayer.push({
		event: action,
		page: {
			page_type: 'Registration'
		},
		verification: {
			listing_name: listing ? listing.name : '',
			listing_id: listing ? listing.id : '',
			campaign_name: listing ? listing.campaignName : '',
			campaign_id: listing ? listing.campaignId : '',
			status: request ? request.status : ''
		}
	})
}

// Push Finish Registration event to the common GovX DataLayer object
export const sendDataLayerFinishRegistration = (isApproved, state) => {
    window.dataLayer = window.dataLayer || []
	let listing = getListing()
	let user = state && state.users && state.users.user ? state.users.user : null
	let isLoggedIn = user !== null && user !== undefined
	let currentOccupation = selectCurrentOccupation(state)
	let affiliationPath = currentOccupation && currentOccupation.path ? currentOccupation.path : ''
	let eventLabel = isLoggedIn ? getTrackingEventLabel(listing) : ''

	window.dataLayer.push({
		event: "finish-registration",
		customer: {
			source_listing: eventLabel,
			email: isLoggedIn ? user.email : '',
			id: isLoggedIn ? user.id : '',
			affiliation_group: affiliationPath
		},
		page: { 
			page_type: 'Registration' 
		},
		verification: {
			listing_name: listing ? listing.name : '',
			listing_id: listing ? listing.id : '',
			campaign_name: listing ? listing.campaignName : '',
			campaign_id: listing ? listing.campaignId : '',
			status: isApproved ? 'approved' : 'pending'
		}
	})
}

const getStepName = (pathname) => {
	if (pathname === null || pathname === undefined || pathname.length <= 1) {
		return ''
	}
	return pathname.substring(1)
}

//ADD DATALAYER ATTRIBUTES ON ALL PAGES
export const trackRender = (location, history, state, pageInfo) => {
	window.dataLayer = window.dataLayer || []
	let eventId = Util.generateW3CId()
	let user = state && state.users && state.users.user ? state.users.user : null
	let isLoggedIn = user !== null && user !== undefined
	let listing = getListing()
	let eventLabel = isLoggedIn ? getTrackingEventLabel(listing) : ''
	let currentOccupation = selectCurrentOccupation(state)
	let affiliationPath = currentOccupation && currentOccupation.path ? currentOccupation.path : ''
	let path = location.pathname.toLowerCase()
	let stepName = getStepName(path)
	
	// Verification Request state is fetched via dispatch and not as readily available as user state
	//  so for Welcome and Pending final pages need to hard code based on path
	let request = selectRequest(state)
	let status = request ? request.status : path === '/register/pending' ? 'Pending' : path === '/register/welcome' ? 'Approved' : ''

	let data = {
		event_id: eventId,
		cart: {
			total: 0,
			quantity: 0
		},
		page: {
			page_type: 'Registration'
		},
		customer: {
			id: isLoggedIn ? user.id : '',
			email: isLoggedIn ? user.email : '',
			login_status: isLoggedIn,
			previous_purchases: '',
			user_segment: isLoggedIn ? 'Partial' : '',
			affiliation_group: affiliationPath,
			source_listing: eventLabel
		},
		verification: {
			listing_name: eventLabel,
			listing_id: listing ? listing.id : '',
			campaign_name: listing ? listing.campaignName : '',
			campaign_id: listing ? listing.campaignId : '',
			status: status
		}
	}
	window.dataLayer.push({ 'event': 'dataLayer_ready', ...data })

	//Execute anytime a step in the registration flow is loaded:
	window.dataLayer.push({
		event: 'form_step',
		form_type: 'member_signup',
		form_step: stepName, // friendly form step name, e.g. occupation
		verification: {
			listing_name: eventLabel,
			listing_id: listing ? listing.id : '',
			campaign_name: listing ? listing.campaignName : '',
			campaign_id: listing ? listing.campaignId : '',
			status: status
		}
	})

	//Execute on page load of the /register/approved and /register/pending pages:
	if (path === '/register/pending' || path === '/register/welcome') {

		window.dataLayer.push({
			event: 'form_complete',
			form_type: 'member_signup',
			customer: {
				source_listing: eventLabel,
				email: isLoggedIn ? user.email : null,
				id: isLoggedIn ? user.id : null,
				affiliation_group: affiliationPath,
			},
			page: { 
				page_type: 'Registration'
			},
			verification: {
				listing_name: eventLabel,
				listing_id: listing ? listing.id : '',
				campaign_name: listing ? listing.campaignName : '',
				campaign_id: listing ? listing.campaignId : '',
				status: status
			}
		})
	}
}

let _lastKnownUser = {}

//GA EVENT HANDLERS
export const googleAnalyticsMiddleware = store => next => action => {
	let result = next(action)
	let state = store.getState()

	let user = selectUser(state)
	if (user != _lastKnownUser) {
			if (user)
				GovXGA.set({userId: user.id})
			else
				GovXGA.set({userId: null})
			_lastKnownUser = user
	}

	let currentOccupation = selectCurrentOccupation(state)
	let affiliationPath = currentOccupation && currentOccupation.path ? currentOccupation.path : null

	let listing = getListing()
	let eventLabel = getTrackingEventLabel(listing)
	switch(action.type) {
		case LOCATION_CHANGE: //Pageview changed
			GovXGA.set({page : action.payload.pathname})
			GovXGA.pageview(action.payload.pathname)
			break

		case REGISTER_EMAIL_REQUEST: //Click "Continue" button on Register page
		    sendDataLayerEvent('start-registration', state)
			sendGAEvent(GACategories.verification, GAActions.signUpWithEmail, eventLabel)
			break

		case REGISTER_EMAIL_SUCCESS: //Success after clicking "Sign Up" button
			GovXGA.set({ dimension1: 'Partial' })
			GovXGA.set({ dimension11: eventLabel })
			break

		case LOGIN_REQUEST: //Click "Log In" button
			sendDataLayerEvent(GAActions.login, state)
			break

		case GOTO_LOGIN_PAGE: //Click "Log In" link
			sendGAEvent(GACategories.verification, GAActions.goToLogin, eventLabel)
			break

		case GOTO_LOGIN_PAGE_HARDREJECTION: // Click "Log In" link from Hard Rejection page
			sendGAEvent(GACategories.verification, GAActions.hardRejectionLogin, eventLabel)
			break

		case GOTO_FORGOT_PASSWORD_PAGE: //Click "Forgot Password?" link on Login page
		    sendDataLayerEvent(GAActions.forgotPW, state)
			break

		case GOTO_FORGOT_PASSWORD_PAGE_HARDREJECTION: //Click "Reset PW" link from Hard Rejection page
			sendGAEvent(GACategories.verification, GAActions.hardRejectionPWReset, eventLabel)
			break

		case FORGOT_PASSWORD_REQUEST: // Forgot Password page (linked from Login page) 'Reset Password' button (Submit Form)
			sendDataLayerEvent(GAActions.requestPWReset, state)
			break

		case RESET_PASSWORD_REQUEST: // Reset Password page (email link sent from Forgot Password page) 'Reset Password' button (Submit Form)
		    sendGAEvent(GACategories.verification, GAActions.resetPW, eventLabel)
			break

		case SAVE_PREQUALIFIERS_REQUEST: //Click "Continue" button on any Pre-Qualification screen
			sendGAEvent(GACategories.verification, GAActions.submitAffiliation, eventLabel)
			break

		case MANUAL_PROCESS_DMDC_REQUEST: //Click "Confirm with DMDC" button on DMDC retry screen
			sendGAEvent(GACategories.verification, GAActions.dmdcRetry, eventLabel)
			break

		case MANUAL_PROCESS_DMDC_FAMILY_REQUEST: //Click "Confirm with DMDC" button on DMDC Family retry screen
			sendGAEvent(GACategories.verification, GAActions.dmdcFamManual, eventLabel)
			break

		case SAVE_EMAILVERIFICATIONREQUEST_REQUEST: //Click "Submit" button on verify/types
			sendGAEvent(GACategories.verification, GAActions.emailVerification, eventLabel)
			break

		case SAVE_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST: //Click "Verify" button on verify/doc/upload
		case SAVE_MANUALDOCUMENTVERIFICATIONREQUEST_REQUEST: //
			sendGAEvent(GACategories.verification, GAActions.docVerification, eventLabel)
			break

		case SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_REQUEST: //Click "Verify" button on verify/family-doc/upload
			sendGAEvent(GACategories.verification, GAActions.dmdcFamilyDocVerification, eventLabel)
			break

		case COMPLETE_REGISTRATION_REQUEST: //Click "Finish Registration" button on /register/approved or /register/pending
			if (window.location.pathname.includes('pending')) {
				let verificationRequest = selectRequest(state)
				sendDataLayerFinishRegistration(false, state)
				if (verificationRequest && verificationRequest.type === 'Phone') {
					sendGAEvent(GACategories.verification, GAActions.registeredPendingPhone, eventLabel)
				}
				else {
					sendGAEvent(GACategories.verification, GAActions.registeredPending, eventLabel)
				}
			}
			else if (window.location.pathname.includes('welcome')) {
				sendDataLayerFinishRegistration(true, state)
				sendGAEvent(GACategories.verification, GAActions.registeredApproved, eventLabel)
			}
			break

		case ADD_LISTING_SPECIAL_OFFER_USER_SUBSCRIPTIONS_REQUEST: //Click "Continue" button on /register/special-offers
			let offerIdsString = 'none'
			if (action.specialOfferIds && action.specialOfferIds.length > 0)
				offerIdsString = action.specialOfferIds.join(', ')
			let actionString = `${GAActions.specialOfferSubscriptionsSubmitted} (${offerIdsString})`
			sendGAEvent(GACategories.verification, actionString, eventLabel)
			break

		case AUTO_PROCESS_AUTO_SUCCESS: //Successful Auto-Approval
		case SAVE_EMAILVERIFICATIONREQUEST_SUCCESS: //Successful Email Verification Request created
		case SAVE_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS: //Successful Doc Upload Auto-Approval
		case SAVE_DMDCFAMILY_AUTODOCUMENTVERIFICATIONREQUEST_SUCCESS: //Successful DMDC Family Doc Upload Auto-Approval
		case AUTO_PROCESS_DMDC_SUCCESS: //Successful DMDC Auto-Approval
		case AUTO_PROCESS_DMDC_FAMILY_SUCCESS: //Successful DMDC Family Auto-Approval
		case SAVE_VAVERIFICATIONREQUEST_SUCCESS: //Successful VA Verification Confirmed
			let request = selectRequest(state)
			if (request.status === 'Approved'){
				window.dataLayer = window.dataLayer || []
				window.dataLayer.push({
					event: 'member_instant_approval',
					form_type: 'member_signup',
					customer: {
						source_listing: eventLabel,
						email: user ? user.email : null,
						id: user ? user.id : null,
						affiliation_group: affiliationPath,
					},
					verification: {
						listing_name: eventLabel,
						listing_id: listing ? listing.id : '',
						campaign_name: listing ? listing.campaignName : '',
						campaign_id: listing ? listing.campaignId : '',
						status: request ? request.status : ''
					}
				})
			}
			break

		case CONFIRM_EMAIL_CODE_REQUEST: //Click "Submit my code" button on /confirm-email
			sendGAEvent(GACategories.verification, GAActions.emailConfirmationCodeSubmitted, eventLabel)
			break

		case RESEND_VERIFICATION_EMAIL_REQUEST: //Click "Resend activation email" button on /confirm-email
			sendDataLayerEvent(GAActions.resendActivationEmail, state)
			break

		default:
			break
	}
	return result
}

export default googleAnalyticsMiddleware