import React from 'react'
import { Route, IndexRoute } from 'react-router'
import App from '../containers/App'
import { appRouter, requireAuth } from './app.router'
import RegisterEmailPageContainerAsync from '../containers/RegisterEmailPageContainer/async'
import LoginPageContainerAsync from '../containers/LoginPageContainer/async'
import VerifyOccupationPageContainerAsync from '../containers/VerifyOccupationPageContainer/async'
import VerifyPrequalPageContainerAsync from '../containers/VerifyPrequalPageContainer/async'
import VerificationTypesPageContainerAsync from '../containers/VerificationTypesPageContainer/async'
import WelcomeScreenPageContainerAsync from '../containers/WelcomeScreenPageContainer/async'
import PendingScreenPageContainerAsync from '../containers/PendingScreenPageContainer/async'
import TodoContainerAsync from '../containers/TodoContainer/async'
import UserConsentPageContainerAsync from '../containers/UserConsentPageContainer/async'
import CallScreenPageContainerAsync from '../containers/CallScreenPageContainer/async'
import ActivationCodePageContainerAsync from '../containers/ActivationCodePageContainer/async'
import ForgotPasswordPageContainerAsync from '../containers/ForgotPasswordPageContainer/async'
import ResetPasswordPageContainerAsync from '../containers/ResetPasswordPageContainer/async'
import ChangePasswordPageContainerAsync from '../containers/ChangePasswordPageContainer/async'
import ReturningUserPageContainerAsync from '../containers/ReturningUserPageContainer/async'
import PartialUserPageContainerAsync from '../containers/PartialUserPageContainer/async'
import ListingUnavailableContainerAsync from '../containers/ListingUnavailableContainer/async'
import OccupationNotCompatiblePageContainerAsync from '../containers/OccupationNotCompatiblePageContainer/async'
import DuplicateAccountPageContainerAsync from '../containers/DuplicateAccountPageContainer/async'
import ConfirmEmailPageContainerAsync from '../containers/ConfirmEmailPageContainer/async'
import SpecialOffersContainerAsync from '../containers/SpecialOffersContainer/async'
import VerifyAccountDeletePageContainerAsync from '../containers/VerifyAccountDeletePageContainer/async'

//Events
import OfferEventsPageContainerAsync from '../containers/OfferEventsPageContainer/async'

//offers
import OfferPageContainerAsync from '../containers/OfferPageContainer/async'
import OfferNotAvailablePageContainerAsync from '../containers/OfferNotAvailablePageContainer/async'
import OfferNotQualifiedPageContainerAsync from '../containers/OfferNotQualifiedPageContainer/async'
import OfferNotFoundPageContainerAsync from '../containers/OfferNotFoundPageContainer/async'
import OfferLimitReachedPageContainerAsync from '../containers/OfferLimitReachedPageContainer/async'
import OfferPendingPageContainerAsync from '../containers/OfferPendingPageContainer/async'

//verification types
import VerifyDMDCPageContainerAsync from '../containers/VerificationTypes/VerifyDMDCPageContainer/async'
import VerifyDMDCFamilyPageContainerAsync from '../containers/VerificationTypes/VerifyDMDCFamilyPageContainer/async'
import VerifyEmailPageContainerAsync from '../containers/VerificationTypes/VerifyEmailPageContainer/async'
import VerifyDocPageContainerAsync, { DocTypeAsync, DocUploadAsync } from '../containers/VerificationTypes/VerifyDocPageContainer/async'
import VerifyFamilyDocUploadPageContainerAsync from '../containers/VerificationTypes/VerifyFamilyDocUploadPageContainer/async'
import VerifyVALookupPageContainerAsync from '../containers/VerificationTypes/VerifyVALookupPageContainer/async'
import VerifyVALookupFailedPageContainerAsync from '../containers/VerificationTypes/VerifyVALookupFailedPageContainer/async'

//discounts/tickets
import DiscountPageContainerAsync from '../containers/Tickets/DiscountPageContainer/async'
import DiscountPopupPageContainerAsync from '../containers/Tickets/DiscountPopupPageContainer/async'
import DiscountPopupRedirectPageContainerAsync from '../containers/Tickets/DiscountPopupRedirectPageContainer/async'
import TicketsPendingPageContainerAsync from '../containers/Tickets/TicketsPendingPageContainer/async'
import EventNotFoundPageContainerAsync from '../containers/Tickets/EventNotFoundPageContainer/async'
import EventNotAvailablePageContainerAsync from '../containers/Tickets/EventNotAvailablePageContainer/async'
import TicketNotFoundPageContainerAsync from '../containers/Tickets/TicketNotFoundPageContainer/async'
import TicketNotAvailablePageContainerAsync from '../containers/Tickets/TicketNotAvailablePageContainer/async'
import UserNotQualifiedPageContainerAsync from '../containers/Tickets/UserNotQualifiedPageContainer/async'
import DiscountTicketsOutOfStockPageContainerAsync from '../containers/Tickets/DiscountTicketsOutOfStockPageContainer/async'
import EntertainerEventsPageContainerAsync from '../containers/Tickets/EntertainerEventsPageContainer/async'
import EntertainerNotFoundPageContainerAsync from '../containers/Tickets/EntertainerNotFoundPageContainer/async'
import AppWrapper from '../components/AppWrapper'
import ExpiredInvitationContainerAsync from '../containers/ExpiredInvitationContainer/async'
import ErrorPageContainerAsync from '../containers/ErrorPageContainer/async'
import RouteConstants from './route-constants'

export default [
	{
		path: '/',
		component: AppWrapper,
		routes: [
			RegisterEmailPageContainerAsync,
			LoginPageContainerAsync,
			ReturningUserPageContainerAsync,
			PartialUserPageContainerAsync,
			WelcomeScreenPageContainerAsync,
			PendingScreenPageContainerAsync,
			ExpiredInvitationContainerAsync,
			ForgotPasswordPageContainerAsync,
			ResetPasswordPageContainerAsync,
			ChangePasswordPageContainerAsync,
			ListingUnavailableContainerAsync,
			OccupationNotCompatiblePageContainerAsync,
			DuplicateAccountPageContainerAsync,
			ConfirmEmailPageContainerAsync,
			SpecialOffersContainerAsync,
			VerifyAccountDeletePageContainerAsync,
			OfferPageContainerAsync,
			OfferNotAvailablePageContainerAsync,
			OfferNotQualifiedPageContainerAsync,
			OfferNotFoundPageContainerAsync,
			OfferLimitReachedPageContainerAsync,
			OfferPendingPageContainerAsync,
			DiscountPageContainerAsync,
			DiscountPopupPageContainerAsync,
			DiscountPopupRedirectPageContainerAsync,
			TicketsPendingPageContainerAsync,
			EventNotFoundPageContainerAsync,
			EventNotAvailablePageContainerAsync,
			TicketNotFoundPageContainerAsync,
			TicketNotAvailablePageContainerAsync,
			UserNotQualifiedPageContainerAsync,
			VerifyPrequalPageContainerAsync,
			VerifyOccupationPageContainerAsync,
			VerificationTypesPageContainerAsync,
			VerifyDMDCPageContainerAsync,
			VerifyDMDCFamilyPageContainerAsync,
			VerifyEmailPageContainerAsync,
			VerifyDocPageContainerAsync,
			DocUploadAsync,
			DocTypeAsync,
			VerifyFamilyDocUploadPageContainerAsync,
			VerifyVALookupPageContainerAsync,
			VerifyVALookupFailedPageContainerAsync,
			CallScreenPageContainerAsync,
			ActivationCodePageContainerAsync,
			UserConsentPageContainerAsync,
			ErrorPageContainerAsync,
			DiscountTicketsOutOfStockPageContainerAsync,
			EntertainerEventsPageContainerAsync,
			EntertainerNotFoundPageContainerAsync,
			OfferEventsPageContainerAsync,
			TodoContainerAsync //Needs to remain last in the route list.
		],
	},
]

export const CreateRoutes = ({ store }) => {
	let router = appRouter(store)

	const redirector = (state, replace, callback) => {
		router(state, replace, callback)
	}

	const authorize = (state, replace, callback) => {
		requireAuth(store)(state, replace, callback)
	}

	return (
		<div>
			<Route path={RouteConstants.home.path} component={App} onEnter={redirector}>

				{/* <Route
					path={RouteConstants.verification.occupation.path + '*'}
					component={VerifyOccupationPageContainer}
					onEnter={authorize}
				/> */}
				{/* <Route
					path={RouteConstants.verification.prequal.path}
					component={VerifyPrequalPageContainer}
					onEnter={authorize}
				/>
				<Route
					path={RouteConstants.verification.types.path}
					component={VerificationTypesPageContainer}
					onEnter={authorize}
				/>
				<Route
					path={RouteConstants.verification.verifyDmdc.path}
					component={VerifyDMDCPageContainer}
					onEnter={authorize}
				/>
				<Route
					path={RouteConstants.verification.verifyEmail.path}
					component={VerifyEmailPageContainer}
					onEnter={authorize}
				/>
				<Route
					path={RouteConstants.verification.verifyDoc.path}
					component={VerifyDocPageContainer}
					onEnter={authorize}
				/>
				<Route
					path={RouteConstants.verification.call.path}
					component={CallScreenPageContainer}
					onEnter={authorize}
				/>
				<Route path={RouteConstants.verification.activationCode.path} component={ActivationCodePageContainer} />
				<Route
					path={RouteConstants.auth.userConsent.path}
					component={UserContentPageContainer}
					onEnter={authorize}
				/> */}
				{/* <Route path={RouteConstants.listingUnavailable.path} component={ListingUnavailableContainer} />
				<Route path={RouteConstants.occupationNotCompatible.path} component={OccupationNotCompatiblePageContainer} />
				<Route path={RouteConstants.duplicateAccount.path} component={DuplicateAccountPageContainer} />
				<Route path={RouteConstants.confirmEmail.path} component={ConfirmEmailPageContainer} /> */}
				/*Tickets*/
				{/* <Route path={RouteConstants.tickets.discount.path} component={DiscountPageContainer} />
				<Route path={RouteConstants.tickets.discountPopup.path} component={DiscountPopupPageContainer} /> */}
				{/* <Route
					path={RouteConstants.tickets.discountPopupRedirect.path}
					component={DiscountPopupRedirectPageContainer}
				/>
				<Route path={RouteConstants.tickets.pending.path} component={TicketsPendingPageContainer} />
				<Route path={RouteConstants.tickets.eventNotFound.path} component={EventNotFoundPageContainer} />
				<Route
					path={RouteConstants.tickets.eventNotAvailable.path}
					component={EventNotAvailablePageContainer}
				/> */}
				{/* <Route path={RouteConstants.tickets.ticketNotFound.path} component={TicketNotFoundPageContainer} />
				<Route
					path={RouteConstants.tickets.ticketNotAvailable.path}
					component={TicketNotAvailablePageContainer}
				/>
				<Route path={RouteConstants.tickets.notQualified.path} component={UserNotQualifiedPageContainer} /> */}
				/*OfferEvents*/
				{/* <Route path={RouteConstants.offerEvents.events.path} component={OfferEventsPageContainer} /> */}
				/*Catch all*/
				{/* <Route path="/**" component={TodoContainer} /> */}
			</Route>
			{/* <Route path={RouteConstants.error.path} component={App} /> */}
		</div>
	)
}

//export RouteConstants, { oauthRedirect } from './route-constants'
