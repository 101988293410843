import MobileDetect from 'mobile-detect'

export const isOldIE = () => {
	if (navigator.appName == 'Microsoft Internet Explorer') {
		var agent = navigator.userAgent
		if (agent.match(/MSIE ([0-9]{1,}[\.0-9]{0,})/) != null) {
			var version = parseFloat( RegExp.$1 )
			if (version < 11)
				return true
		}
	}
	return false
}

export const isMobile = new MobileDetect(window.navigator.userAgent).mobile()

export const isSmallScreen = () => {
	return window.screen.availWidth == 320
}

export const isChromeIos = () => {
	return navigator.userAgent.match('CriOS')
}

export const isAndroid = () => { // This is pretty generic but it shouldn't matter. Only using it for one bug fix currently...
	return /(android)/i.test(navigator.userAgent)
}