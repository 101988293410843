import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../setup/route-constants'

const LoadableTodoContainer= Loadable({
	loader: () => import(/* webpackChunkName: "todo" */ './'),
	loading: () => null
})

export default {
	name: 'error',
	path: '*',
	page: true,
	component: LoadableTodoContainer,
	lifecycle: {
	},
	config: () => ({

	})
}