const logger = store => next => action => {
	let result = next(action)
	let isReduxForm = typeof action !== 'function' && action.type.indexOf('redux-form') > 0
	if (!isReduxForm) { // Gets kind of spammy
		console.group(action.type)
		console.info('dispatching', action)
		console.log('next state', store.getState())
		console.groupEnd(action.type)
	}
	return result
}

export default logger