export const PREPARE_PROCESSING = 'PREPARE_PROCESSING'
export const SET_PROCESSING_MESSAGE = 'SET_PROCESSING_MESSAGE'
export const BEGIN_PROCESSING = 'BEGIN_PROCESSING'
export const END_PROCESSING = 'END_PROCESSING'

export const prepareProcessing = height => dispatch => {
	dispatch({ type: PREPARE_PROCESSING, height })
}

export const setProcessingMessage = message => dispatch => {
	dispatch({ type: SET_PROCESSING_MESSAGE, message })
}

export const beginProcessing = message => dispatch => {
	dispatch({ type: BEGIN_PROCESSING, message })
}

export const endProcessing = () => dispatch => {
	dispatch({type: END_PROCESSING})
}

export const STATE_KEY = 'processing'

export const DEFAULT_STATE = {
	isProcessing: false,
	message: null,
	height: 0,
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case PREPARE_PROCESSING:
			return { ...state, height: action.height }
		case SET_PROCESSING_MESSAGE:
			return { ...state, message: action.message }
		case BEGIN_PROCESSING:
			return { ...state, isProcessing: true, message: action.message }
		case END_PROCESSING:
			return DEFAULT_STATE
		default:
			return { ...state }
	}
}

export default reducer

export const selectProcessing = state => state.processing