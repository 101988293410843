import * as ticketsApi from '../../api/ticketsApi'
import { normalize, denormalize } from 'normalizr'

export const FETCH_DISCOUNT_REQUEST = 'FETCH_DISCOUNT_REQUEST'
export const FETCH_DISCOUNT_SUCCESS = 'FETCH_DISCOUNT_SUCCESS'
export const FETCH_DISCOUNT_FAILURE = 'FETCH_DISCOUNT_FAILURE'

export const fetchDiscount = token => (dispatch, getState) => {
	dispatch({type: FETCH_DISCOUNT_REQUEST})
	return ticketsApi.fetchDiscountFromToken(token)
		.then(resp => {
			dispatch({type: FETCH_DISCOUNT_SUCCESS, discount: resp.ticketDiscountModel})
		})
		.catch(err => {
			dispatch({type: FETCH_DISCOUNT_FAILURE, err})
			throw err
		})
}

export const GOTO_DISCOUNT_LINK = 'GOTO_DISCOUNT_LINK'

export const gotoDiscountLink = discount => (dispatch, getState) => {
	dispatch({type: GOTO_DISCOUNT_LINK}, discount)
	window.location.href = discount.discountLink
}

export const GOTO_DISCOUNT_LINK_POPUP = 'GOTO_DISCOUNT_LINK_POPUP'

export const gotoDiscountLinkPopup = discountLink => (dispatch, getState) => {
	console.log('REDIRECT OPENER TO: ', discountLink)
	dispatch({type: GOTO_DISCOUNT_LINK_POPUP}, discountLink)
	if (window.opener != null) {
		window.opener.postMessage(discountLink, '*')
		window.close()
	}
	else {
		window.location.href = discountLink
	}
}

export const FETCH_ENTERTAINER_REQUEST = 'FETCH_ENTERTAINER_REQUEST'
export const FETCH_ENTERTAINER_SUCCESS = 'FETCH_ENTERTAINER_SUCCESS'
export const FETCH_ENTERTAINER_FAILURE = 'FETCH_ENTERTAINER_FAILURE'

export const fetchEntertainer = id => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_ENTERTAINER_REQUEST })
	return ticketsApi.fetchEntertainer(id)
		.then(response => {
			let data = normalize(response, schema.entertainer)
			dispatch({ type: FETCH_ENTERTAINER_SUCCESS, data })
			return response
		})
		.catch(err => {
			dispatch({ type: FETCH_ENTERTAINER_FAILURE, err })
			throw err
		})
}

export const FETCH_ENTERTAINER_EVENTTICKETS_REQUEST = 'FETCH_ENTERTAINER_EVENTTICKETS_REQUEST'
export const FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS = 'FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS'
export const FETCH_ENTERTAINER_EVENTTICKETS_FAILURE = 'FETCH_ENTERTAINER_EVENTTICKETS_FAILURE'

export const fetchEntertainerEventTickets = (entertainerId, clientId) => (dispatch, getState, { schema }) => {
	dispatch({ type: FETCH_ENTERTAINER_EVENTTICKETS_REQUEST })
	return ticketsApi.fetchEntertainerEventTickets(entertainerId, clientId)
		.then(response => {
			let data = normalize(response, [schema.eventTicket])
			dispatch({ type: FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS, data, entertainerId })
			return response
		}).catch(err =>{
			dispatch({ type: FETCH_ENTERTAINER_EVENTTICKETS_FAILURE, err })
			throw err
		})
}

export const STATE_KEY = 'tickets'

export const DEFAULT_STATE = {
	discount: null,
	entertainers: {},
	eventTickets: {},
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_DISCOUNT_SUCCESS:
			return {...state, discount: action.discount }
		case FETCH_ENTERTAINER_SUCCESS:
			return { ...state, entertainers: { ...state.entertainers, ...action.data.entities.entertainers }}
		case FETCH_ENTERTAINER_EVENTTICKETS_SUCCESS:
			return { ...state, eventTickets: { ...state.eventTickets, [action.entertainerId]: action.data.entities.eventTickets }}
		default:
			return {...state}
	}
}

export default reducer

//selectors

export const selectDiscount = state => state.tickets.discount

export const selectEntertainer = state => id => state.tickets.entertainers[id]

export const selectEventTickets = state => entertainerId => {
	if (!state.tickets.eventTickets[entertainerId])
		return []
	return Object.keys(state.tickets.eventTickets[entertainerId]).map(k => state.tickets.eventTickets[entertainerId][k])
}
