import store from 'store'

const prefix = 'verify.govx.com.'
const version = prefix + '0.'

const FONTS_LOADED_KEY = version + 'fonts-loaded'

export const cleanup = () => {
	if (!store.enabled)
		return
	let removeKeys = []
	store.forEach(k => {
		if (k.startsWith(prefix) && !k.startsWith(version)) {
			removeKeys.push(k)
		}
	})

	removeKeys.forEach(k => store.remove(k))
}
cleanup()

export const safeGet = key => store.enabled ? store.get(key) : null

export const safeSet = (key, value) => {
	if (store.enabled)
		store.set(key, value)
}

export const safeRemove = key => {
	if (store.enabled)
		store.remove(key)
}

export const getFontsLoaded = () => safeGet(FONTS_LOADED_KEY)
export const setFontsLoaded = () => safeSet(FONTS_LOADED_KEY, true)
export const removeFontsLoaded = () => safeRemove(FONTS_LOADED_KEY)