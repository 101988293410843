import * as authenticationApi from '../../api/authenticationApi'
import { RouteConstants, oauthRedirect } from '../../setup/route-constants'
import { getRequireEmailConfirmation } from '../../setup/configuration'
import { history } from '../../history'

//actions

export const REGISTER_EMAIL_REQUEST = 'REGISTER_EMAIL_REQUEST'
export const REGISTER_EMAIL_SUCCESS = 'REGISTER_EMAIL_SUCCESS'
export const REGISTER_EMAIL_FAILURE = 'REGISTER_EMAIL_FAILURE'

export const registerEmail = email => (dispatch, getState, { session }) => {
	dispatch({type: REGISTER_EMAIL_REQUEST})
	session.clearSession()
	return authenticationApi.registerEmail(email)
		.then(response => {
			dispatch({type: REGISTER_EMAIL_SUCCESS, email: response.email })
			return dispatch(requireUser())
				.then(x => {
					if (getRequireEmailConfirmation()) {
						return dispatch(sendVerificationEmail(false))
							.then(y => {
								history.push(RouteConstants.confirmEmail.path)
								return response
							})
					}
					else {
						history.push(RouteConstants.verification.occupation.path)
						return response
					}
				})
		})
		.catch(err => {
			if (err.code === 'email_already_exists_with_password')
			history.push(RouteConstants.login.returning.path + `?email=${encodeURIComponent(email)}`)
			else if (err.code === 'email_already_exists_no_password')
			history.push(RouteConstants.login.partial.path + `?email=${encodeURIComponent(email)}`)
			dispatch({type: REGISTER_EMAIL_FAILURE, err})
			throw err
		})
}

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'

export const requireUser = () => (dispatch, getState, { session }) => {
	let state = getState()
	let user = selectUser(state)
	if (user && (user.emailConfirmed || user.hasPassword))
		return new Promise(resolve => resolve(state.users.user))

	dispatch({type: FETCH_USER_REQUEST})

	return authenticationApi.getCurrentUser()
		.then(response => {
			dispatch({type: FETCH_USER_SUCCESS, user: response})
			return response
		})
		.catch(err => {
			dispatch({type: FETCH_USER_FAILURE, err})
			throw err
		})
}

export const requireUserWithNames = () => (dispatch, getState, { session }) => {
	let state = getState()
	let user = selectUser(state)
	if (user && (user.firstName|| user.lastName))
		return new Promise(resolve => resolve(state.users.user))

	dispatch({type: FETCH_USER_REQUEST})

	return authenticationApi.getCurrentUser()
		.then(response => {
			dispatch({type: FETCH_USER_SUCCESS, user: response})
			return response
		})
		.catch(err => {
			dispatch({type: FETCH_USER_FAILURE, err})
			throw err
		})
}

export const fetchUser = () => (dispatch, getState, { session }) => {
	dispatch({type: FETCH_USER_REQUEST})

	return authenticationApi.getCurrentUser()
		.then(response => {
			dispatch({type: FETCH_USER_SUCCESS, user: response})
			return response
		})
		.catch(err => {
			dispatch({type: FETCH_USER_FAILURE, err})
			throw err
		})
}

export const COMPLETE_REGISTRATION_REQUEST = 'COMPLETE_REGISTRATION_REQUEST'
export const COMPLETE_REGISTRATION_SUCCESS = 'COMPLETE_REGISTRATION_SUCCESS'
export const COMPLETE_REGISTRATION_FAILURE = 'COMPLETE_REGISTRATION_FAILURE'

export const WAITING_FOR_PHONECALL = 'WAITING_FOR_PHONECALL'
export const CONTINUE_FROM_PHONECALL = 'CONTINUE_FROM_PHONECALL'

export const completeRegistration = values => (dispatch, getState) => {
	dispatch({ type: COMPLETE_REGISTRATION_REQUEST })
	let state = getState()
	return authenticationApi.completeRegistration(values)
		.then(response => {
			dispatch({ type: COMPLETE_REGISTRATION_SUCCESS, response })

			if (state.verification.request && state.verification.request.type === 'Phone') {
				dispatch({ type: WAITING_FOR_PHONECALL, redirect: { url: response.redirectUrl }})
				history.push(RouteConstants.verification.call.path)
			} else
				window.location.href = response.redirectUrl

			return response
		})
		.catch(err => {
			dispatch({ type: COMPLETE_REGISTRATION_FAILURE, err })
			throw err
		})
}

export const continueFromPhoneCall = redirect => (dispatch, getState) => {
	dispatch({ type: CONTINUE_FROM_PHONECALL })
	window.location.href = redirect.url
	return new Promise(resolve => resolve({}))
}

export const GOTO_LOGIN_PAGE = 'GOTO_LOGIN_PAGE'
export const GOTO_LOGIN_PAGE_HARDREJECTION = 'GOTO_LOGIN_PAGE_HARDREJECTION'

export const goToLoginPage = sourceType => dispatch => {
	dispatch({type: sourceType || GOTO_LOGIN_PAGE})
	history.push(RouteConstants.login.index.path)
}

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

const setLoginAttemptSuccessful = (successful) => {
	let pathname = history.location.pathname
	let searchParams = new URLSearchParams(history.location.search)
	searchParams.set('loginAttemptSuccessful', successful)
	history.replace(pathname + '?' + searchParams.toString())
}

export const login = (data, returnUrl) => (dispatch, getState, { session }) => {
	dispatch({type: LOGIN_REQUEST})
	session.clearSession()
	return authenticationApi.login(data)
		.then(response => {
			setLoginAttemptSuccessful('true')
			dispatch({type: LOGIN_SUCCESS, user: response})

			if (returnUrl)
				window.location.href = returnUrl
			else
				oauthRedirect()
			return response
		})
		.catch(err => {
			setLoginAttemptSuccessful('false')
			dispatch({type: LOGIN_FAILURE, err})
			throw err
		})
}

export const GOTO_FORGOT_PASSWORD_PAGE = 'GOTO_FORGOT_PASSWORD_PAGE'
export const GOTO_FORGOT_PASSWORD_PAGE_HARDREJECTION = 'GOTO_FORGOT_PASSWORD_PAGE_HARDREJECTION'

export const goToForgotPasswordPage = sourceType => dispatch => {
	dispatch({type: sourceType || GOTO_FORGOT_PASSWORD_PAGE})
}

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

const setResetPasswordEmailFound = (found) => {
	let pathname = history.location.pathname
	let searchParams = new URLSearchParams(history.location.search)
	searchParams.set('resetPasswordEmailFound', found)
	history.replace(pathname + '?' + searchParams.toString())
}

export const forgotPassword = email => (dispatch, getState) => {
	dispatch({type: FORGOT_PASSWORD_REQUEST})
	return authenticationApi.forgotPassword(email)
		.then(response => {
			setResetPasswordEmailFound('true')
			dispatch({type: FORGOT_PASSWORD_SUCCESS, response})
			return response
		})
		.catch(err => {
			setResetPasswordEmailFound('false')
			dispatch({type: FORGOT_PASSWORD_FAILURE, err})
			throw err
		})
}

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export const resetPassword = model => (dispatch, getState, { session }) => {
	dispatch({type: RESET_PASSWORD_REQUEST})
	session.clearSession()
	return authenticationApi.resetPassword(model)
		.then(response => {
			setResetPasswordEmailFound('true')
			dispatch({type: RESET_PASSWORD_SUCCESS, response})
			if (response.redirectUri)
				window.location = response.redirectUri
			else
				oauthRedirect()
			return response
		})
		.catch(err => {
			setResetPasswordEmailFound('false')
			dispatch({type: RESET_PASSWORD_FAILURE, err})
			throw err
		})
}

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const changePassword = model => (dispatch, getState, { session }) => {
	dispatch({type: CHANGE_PASSWORD_REQUEST})
	session.clearSession()
	return authenticationApi.changePassword(model)
		.then(response => {
			dispatch({type: CHANGE_PASSWORD_SUCCESS, response})
			return response
		})
		.catch(err => {
			dispatch({type: CHANGE_PASSWORD_FAILURE, err})
			throw err
		})
}

export const CONFIRM_EMAIL_CODE_REQUEST = 'CONFIRM_EMAIL_CODE_REQUEST'
export const CONFIRM_EMAIL_CODE_SUCCESS = 'CONFIRM_EMAIL_CODE_SUCCESS'
export const CONFIRM_EMAIL_CODE_FAILURE = 'CONFIRM_EMAIL_CODE_FAILURE'

export const confirmEmailCode = code => (dispatch, getState, { session }) => {
	dispatch({type: CONFIRM_EMAIL_CODE_REQUEST})

	return authenticationApi.confirmEmailCode(code)
		.then(response => {
			dispatch({type: CONFIRM_EMAIL_CODE_SUCCESS, response})
			history.push(RouteConstants.verification.occupation.path)
			return response
		})
		.catch(err => {
			dispatch({type: CONFIRM_EMAIL_CODE_FAILURE, err})
			throw err
		})
}

export const SEND_VERIFICATION_EMAIL_REQUEST = 'SEND_VERIFICATION_EMAIL_REQUEST'
export const RESEND_VERIFICATION_EMAIL_REQUEST = 'RESEND_VERIFICATION_EMAIL_REQUEST'
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS'
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'RESEND_VERIFICATION_EMAIL_SUCCESS'
export const SEND_VERIFICATION_EMAIL_FAILURE = 'SEND_VERIFICATION_EMAIL_FAILURE'

export const sendVerificationEmail = (resending) => (dispatch, getState, { session }) => {
	if (resending)
		dispatch({type: RESEND_VERIFICATION_EMAIL_REQUEST})
	else
		dispatch({type: SEND_VERIFICATION_EMAIL_REQUEST})

	return authenticationApi.sendVerificationEmail()
		.then(response => {
			dispatch({type: SEND_VERIFICATION_EMAIL_SUCCESS, response})
			if (resending)
				dispatch({type: RESEND_VERIFICATION_EMAIL_SUCCESS, response})
			return response
		})
		.catch(err => {
			dispatch({type: SEND_VERIFICATION_EMAIL_FAILURE, err})
			throw err
		})
}

export const STATE_KEY = 'users'

export const DEFAULT_STATE = {
	user: null
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_USER_SUCCESS:
			return { ...state, user: action.user }
		case FETCH_USER_FAILURE:
			return { ...state }
		default:
			return state
	}
}

export default reducer

export const selectUser = state => state.users.user
export const isLoggedIn = state => state.users.user != null
export const isEmailConfirmed = state => state.users.user && state.users.user.emailConfirmed
export const hasPassword = state => state.users.user && state.users.user.hasPassword