import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../../setup/route-constants'

const LoadableUserNotQualifiedPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "tickets.user.not.qualified" */ './UserNotQualifiedPageContainer'),
	loading: () => null
})

export default {
	name: 'tickets user not qualified',
	path: RouteConstants.tickets.notQualified.path,
	page: true,
	component: LoadableUserNotQualifiedPageContainer,
	lifecycle: {
		// authorize: (match, location, { getState }) => {
		// 	var user = selectUser(getState())
		// 	return { authorized : !!user && user.isApproved, redirect: '/' }
		// }
	},
	config: () => ({

	})
}