import React from 'react'
import GovXFooter from './GovXFooter'
import CoBrandedFooter from './CoBrandedFooter'
import PrivateLabelFooter from './PrivateLabelFooter'

export default (listing, uiSettings, listingType) => {
	switch(listingType) {
		case 'CoBranded':
			return <CoBrandedFooter settings={ uiSettings.footer } />
		case 'PrivateLabel':
			return <PrivateLabelFooter settings={ uiSettings.footer } />
		case 'GovX':
		default:
			return <GovXFooter settings={ uiSettings.footer } />
	}
}