import React from "react";
import Loadable from "react-loadable";
import RouteConstants from "../../../setup/route-constants";
import { isLoggedIn } from "../../../redux/selectors";
import { requireUser } from "../../../redux/actions";


const LoadableVerifyVALookupFailedPageContainer = Loadable({
  loader: () => import(/* webpackChunkName: "verification.verify.valookupfailed" */ "./VerifyVALookupFailedPageContainer"),
  loading: () => null
});

export default {
  name: "verification verify va lookup failed",
  path: RouteConstants.verification.verifyVaLookupFailed.path,
  page: true,
  component: LoadableVerifyVALookupFailedPageContainer,
  lifecycle: {
    authorize: (match, location, { getState, dispatch }, history) => {
      return dispatch(requireUser()).then(response => {
        if (!isLoggedIn(getState())) {
          history.replace({ pathname: "/login", search: `?returnUrl=${location.pathname}` });
          return { authorized: false };
        };
        return { authorized: true };
      });
    },
  },
  config: () => ({

  })
};