import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = {
	footer: {
		height: '60px',
		backgroundColor: props => props.settings.backgroundColor,
		color: props => props.settings.linkColor,
		bottom: 0,
		position:'absoluate',
		'@media (min-width: 575px)': {
			position: 'absolute',
			right: '0px',
			bottom: '0px',
			left: '0px',
			margin: '0px auto',
		},
	},
	list: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0px',
		padding: '0px',
	},
	listItem: {
		display: 'inline',
		margin: '0px 20px',

		'@media (max-width: 574px)': {
			fontSize: '12px',
			textAlign: 'center',
		},
	},
	link: {
		color: '#fff',
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'underline',
		},
	},
}

export const GovXFooter = ({ classes, settings }) => (
	<div className={classes.footer}>
		<ul className={classes.list}>
			<li className={classes.listItem}>
				<a href="https://www.govx.com/t/govx-id" className={classes.link} target="_blank" rel='noopener'>
					About GOVX ID
				</a>
			</li>
			<li className={classes.listItem}>
				<a href="https://support.govx.com/section?id=1023" className={classes.link} target="_blank" rel='noopener'>
					FAQs
				</a>
			</li>
			<li className={classes.listItem}>
				<a href="https://www.govx.com/privacyinfo" className={classes.link} target="_blank" rel='noopener'>
					Privacy
				</a>
			</li>
			<li className={classes.listItem}>
				<a href="https://www.govx.com/t/termsofservice" className={classes.link} target="_blank" rel='noopener'>
					Terms of Service
				</a>
			</li>
		</ul>
	</div>
)

GovXFooter.propTypes = {
	classes: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
}

export default injectSheet(styles)(GovXFooter)
