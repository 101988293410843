import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUISettings } from '../../setup/configuration'
import StyledLink from '../StyledLink'
import { RouteConstants } from '../../setup/route-constants'
import injectSheet from 'react-jss'

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		'@media (max-width: 574px)': {
			minHeight: props => uiSettings.footer.showGovXLogo && uiSettings.footer.showFooterLinks ? 'calc(100vh - 165px)' : 'calc(100vh - 145px)',
		},
		'@media (min-width: 575px)': {
			height: '300px',
		},
	},
}

export const ErrorScreen = ({
	classes,
}) => (
	<div className={ classes.container }>
		<h3 className='no-margin no-padding'>Oops, an unexpected error has occurred.</h3>
		<p className='no-margin-bottom no-padding-bottom'>Please refresh the page to continue the verification process. If you're still experiencing issues, <StyledLink contents='click here' to={{ pathname: RouteConstants.register.index.path }} />.</p>
		<div hidden>TEST</div>
	</div>
)

ErrorScreen.propTypes = {
	classes: PropTypes.object.isRequired,
	uiSettings: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
	uiSettings: getUISettings(),
})

export default connect(mapStateToProps)(injectSheet(styles)(ErrorScreen))