import Cookies from 'universal-cookie'

const cookies = new Cookies()

const DEFAULT_SESSION = {
	occupationId: 0,
	requestId: 0,
	progressId: 0,
	display: "full"
}

const SESSION_COOKIE_KEY = 'gvx_s.0'

let currentSession = { ...DEFAULT_SESSION, ...cookies.get(SESSION_COOKIE_KEY) }

export const setSession = props => {
	currentSession = { ...currentSession, ...props }
	cookies.set(SESSION_COOKIE_KEY, currentSession, {path: '/'})
}

export const getSession = () => currentSession

export const clearSession = () => {
	cookies.set(SESSION_COOKIE_KEY, DEFAULT_SESSION, { path: '/'})
}