export const RouteConstants =  {
	home: { path: '/' },
	error: { path: '/error' },
	login: {
		index: {
			path: '/login'
		},
		returning: {
			path: '/returning'
		},
		partial: {
			path: '/partial'
		}
	},
	forgotPassword: { path: '/forgot-password' },
	resetPassword: { path: '/reset-password' },
	changePassword: { path: '/change-password' },
	auth: {
		userConsent: {
			path: '/oauth/user_consent'
		},
		redirect: {
			path: '/oauth/redirect'
		},
		specialOffersSubmitted: {
			path: '/oauth/special-offers-submitted'
		}
	},
	register: {
		index: {
			path: '/register'
		},
		welcome: {
			path: '/register/welcome'
		},
		pending: {
			path: '/register/pending'
		},
		expired: {
			path: '/register/expired'
		},
		specialOffers: {
			path: '/register/special-offers'
		},
		discount: {
			root: {
				path: '/offer/discount'
			},
			notAvailable: {
				path: '/offer/not-available'
			},
			notQualified: {
				path: '/offer/not-qualified'
			},
			notFound: {
				path: '/offer/not-found'
			},
			limitReached: {
				path: '/offer/limit-reached'
			},
			pending: {
				path: '/offer/verification-pending'
			},
		}
	},
	offerEvents: {
		events: {
			path: '/event/offers'
		}
	},
	verification: {
		occupation: { path: '/verify/occupation' },
		prequal: { path: '/verify/prequal' },
		types: { path: '/verify/types' },
		processDMDC: { path: '/verify/process-dmdc' },
		manualDMDC: { path: '/verify/manual-dmdc' },
		processDMDCFamily: { path: '/verify/process-dmdc-family' },
		manualDMDCFamily: { path: '/verify/manual-dmdc-family' },
		autoDocFailed: { path: '/verify/types/auto-doc-failed' },
		autoFamilyDocFailed: { path: '/verify/types/auto-family-doc-failed' },
		call: { path: '/verify/call' },
		activationCode: { path: '/verify/activation-code' },

		verifyDmdc: { path: '/verify/dmdc' },
		verifyDmdcFamily: { path: '/verify/dmdc-family' },
		verifyEmail: { path: '/verify/email' },
		verifyDoc: {
			index: {
				path: '/verify/doc'
			},
			subType: {
				path: '/verify/doc/:type'
			},
			upload: {
				path: '/verify/doc/upload'
			}
		},
		verifyFamilyDocUpload: { path: '/verify/family-doc-upload' },
		verifyVaLookup: { path: '/verify/va-lookup' },
		verifyVaLookupFailed: { path: '/verify/va-lookup-failed' },
	},
	confirmConsumerDelete: { path: '/confirm-consumer-delete' },
	listingUnavailable : { path: '/listing-unavailable' },
	occupationNotCompatible : { path: '/occupation-not-compatible' },
	duplicateAccount : { path: '/duplicate-account' },
	confirmEmail : { path: '/confirm-email' },
	tickets: {
		discount: { path: '/tickets/discount' },
		discountPopup: { path: '/tickets/discount-popup' },
		discountPopupRedirect: { path: '/tickets/discount-popup-redirect' },
		pending: { path: '/tickets/verification-pending' },
		eventNotFound: { path: '/tickets/event-not-found' },
		eventNotAvailable: { path: '/tickets/event-not-available' },
		ticketNotFound: { path: '/tickets/ticket-not-found' },
		ticketNotAvailable: { path: '/tickets/ticket-not-available' },
		notQualified: { path: '/tickets/not-qualified' },
		discountTicketsOutOfStock: { path: '/tickets/no-code'},
		entertainerEvents: { path: '/tickets/events'},
		entertainerNotFound: { path: '/tickets/entertainer-not-found' },
	}
}

export const oauthRedirect = () => {
	window.location.href = RouteConstants.auth.redirect.path
}

export default RouteConstants
