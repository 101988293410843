import { ApplicationInsights, Util } from '@microsoft/applicationinsights-web'

let _ai;

const ReactAI = {
	init: (config, history) => {

		_ai = new ApplicationInsights({
			config: {
				...config,
				disableFetchTracking: false,
				disableAjaxTracking: true
			}
		})
		
		_ai.loadAppInsights()

		_ai.addTelemetryInitializer(envelope => {
			envelope.tags['ai.cloud.role'] = 'auth-client'
		})
	},

	ai: () => _ai,

	startTrackPage: (pageViewTelemetry) => {
		_ai.properties.context.telemetryTrace.traceID = Util.newId()
		_ai.startTrackPage(pageViewTelemetry)
	},

	stopTrackPage: (pageViewTelemetry) => {
		_ai.stopTrackPage(pageViewTelemetry)
	},

	startPageLoad: (location) => {
		_ai.startTrackEvent('PageLoad')
	},

	stopPageLoad: telemetry => {

		let { preload, fetch, render, ...compiled } = telemetry

		if (preload && preload.start && preload.stop) {
			compiled.preload = preload.stop - preload.start
		}

		if (fetch && fetch.start && fetch.stop) {
			compiled.fetch = fetch.stop - fetch.start
		}

		if (render && render.start && render.stop) {
			compiled.render = render.stop - render.start
		}

		_ai.stopTrackEvent('PageLoad', compiled)
	}
}

export default ReactAI