import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'

const styles = {
	link: {
		color: '#0e5777',
		textDecoration: 'none',
		cursor: 'pointer',

		'&:hover, &:focus': {
			textDecoration: 'underline',
		},
	},
}

export const StyledLink = ({ classes, to, onClick, contents, newWindow = false, styles, external, clickHandlerOnly = false }) => (
	external
	?	<a href={ to } className={ classes.link } target={ newWindow ? '_blank' : '' } style={ styles }>{ contents }</a>
	:	<Link to={ to ? to : '/' } onClick={ (e) => {
		onClick && onClick()
		clickHandlerOnly && e.preventDefault()
	 } } className={ classes.link } target={ newWindow ? '_blank' : '' } style={ styles }>{ contents }</Link>
)

StyledLink.propTypes = {
	classes: PropTypes.object.isRequired,
	contents: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	styles: PropTypes.object,
	to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	external: PropTypes.bool,
}

export default injectSheet(styles)(StyledLink)