import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import CircularProgress from "@mui/material/CircularProgress";
import { selectProcessing } from "../../redux/selectors";
import { getUISettings } from "../../setup/configuration";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    marginTop: "0px",
    padding: "50px 0px",
    "@media (max-width: 574px)": {
      minHeight: (props) =>
        props.uiSettings.footer.showGovXLogo &&
        props.uiSettings.footer.showFooterLinks
          ? "calc(100vh - 165px)"
          : "calc(100vh - 145px)",
    },
    "@media (min-width: 575px)": {
      height: (props) => `${props.containerHeight}px`,
    },
  },
};

export const ProcessingScreen = ({ message, classes }) => (
  <div className={classes.container} style={{ background: "white" }}>
    <CircularProgress style={{ color: "#00a1df" }} />
    {message && (
      <p
        className="text-center"
        style={{ maxWidth: "100%", backgroundColor: "white" }}
      >
        {message}
      </p>
    )}
  </div>
);

ProcessingScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  containerHeight: PropTypes.number,
  uiSettings: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  message: selectProcessing(state).message,
  uiSettings: getUISettings(),
  containerHeight: selectProcessing(state).height,
});

export default connect(mapStateToProps)(injectSheet(styles)(ProcessingScreen));
