import * as defaultFetchOptions from './defaultFetchOptions'
import { getAPIDomain } from '../setup/configuration'

export const DEFAULT_TIMEOUT = 20000

export const DEFAULT_OPTIONS = {
	useApiDomain: true,
	timeout: DEFAULT_TIMEOUT
}

export const withTimeout = (fetch, timeout = DEFAULT_TIMEOUT) => {
	let timeoutPromise = new Promise((resolve, reject) => {
		setTimeout(reject, timeout, {fetch: 'timeout'})
	})

	return Promise.race([timeoutPromise, fetch])
		.then(response => response)
		.catch(err => { throw err })
}

export const checkStatus = response => {
	if (response.ok) {
		return response
	} else if (response.status == 404) {
		let error = new Error('not found')
		error.response = response
		throw error
	}

	if (response.headers.get('Content-Type').indexOf('application/json') !== -1)
		return parseJson(response).then(errBody => { throw errBody })

	let error = new Error('Unhandled fetch error')
	error.response = response
	throw error
}

export const parseJson = response => {
	return response.json()
}

export const getJson = (url, options = DEFAULT_OPTIONS) => {
	options = {...DEFAULT_OPTIONS, ...options}

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return withTimeout(fetch(url, defaultFetchOptions.getJson()), options.timeout)
		.then(checkStatus)
		.then(parseJson)
}

export const postJson = (url, data, options = DEFAULT_OPTIONS) => {
	options = {...DEFAULT_OPTIONS, ...options}

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return withTimeout(fetch(url, defaultFetchOptions.postJson(data)), options.timeout)
		.then(checkStatus)
		.then(parseJson)
}

export const postFormData = (url, formData, options = DEFAULT_OPTIONS) => {
	options = {...DEFAULT_OPTIONS, ...options}

	if (options.useApiDomain)
		url = getAPIDomain() + url

	return withTimeout(fetch(url, defaultFetchOptions.postFormData(formData)), options.timeout)
		.then(checkStatus)
		.then(parseJson)
}