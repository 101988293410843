import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../../setup/route-constants'

const LoadableTicketNotAvailablePageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "tickets.ticket.not.available" */ './TicketNotAvailablePageContainer'),
	loading: () => null
})

export default {
	name: 'tickets ticket not available',
	path: RouteConstants.tickets.ticketNotAvailable.path,
	page: true,
	component: LoadableTicketNotAvailablePageContainer,
	lifecycle: {
		// authorize: (match, location, { getState }) => {
		// 	var user = selectUser(getState())
		// 	return { authorized : !!user && user.isApproved, redirect: '/' }
		// }
	},
	config: () => ({

	})
}