export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR'
export const REMOVE_GLOBAL_ERROR = 'REMOVE_GLOBAL_ERROR'

export const setGlobalError = error => dispatch => {
	dispatch({ type: SET_GLOBAL_ERROR, error })
}

export const STATE_KEY = 'errors'

export const DEFAULT_STATE = {
	showErrorScreen: false,
}

const reducer = (state = DEFAULT_STATE, action) => {
	const { type, error } = action

	switch (type) {
		case REMOVE_GLOBAL_ERROR:
			return DEFAULT_STATE
		case SET_GLOBAL_ERROR:
			return { ...state, showErrorScreen: true }
		default:
			return { ...state }
	}
}

export default reducer

export const selectErrors = state => state.errors