import { history } from "./history";
import { setConfiguration } from "./setup/configuration";
import { loadFontFaceObserver } from "./setup";
import "./css/base.css";
import ReactAI from "./utils/ReactAI";
import { trackRender } from "./middleware/GoogleAnalyticsMiddleware";
import configureAppStore from "./redux/configureStore";

let configuration = setConfiguration(window.__GOVX_CONFIGURATION__);
delete window.__GOVX_CONFIGURATION__;

ReactAI.init(
  {
    instrumentationKey: configuration.instrumentationKey,
  },
  history
);
(function () {
  loadFontFaceObserver();
})();

let store = configureAppStore();

export const init = () => {
  return new Promise((resolve, reject) => {
    // do some async task
    resolve({ store, history });
  });
};

export const refresh = () => {
  return new Promise((resolve, reject) => {
    // do some async task
    resolve({ store, history });
  });
};

export const pageRendered = (pageInfo) => {
  trackRender(window.location, history, store.getState(), pageInfo);
};
