import * as offersApi from '../../api/offersApi'
//actions

export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST'
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS'
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE'

export const fetchOffers = partnerListingId => (dispatch, getState) => {
	dispatch({type: FETCH_OFFERS_REQUEST})
	return offersApi.fetchOffers(partnerListingId)
		.then(resp => {
			dispatch({type: FETCH_OFFERS_SUCCESS, offers: resp})
		})
		.catch(err => {
			dispatch({type: FETCH_OFFERS_FAILURE, err})
			throw err
		})
}

export const STATE_KEY = 'offers'

export const DEFAULT_STATE = {
	offers: null
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_OFFERS_SUCCESS:
			return {...state, offers: action.offers }
		default:
			return {...state}
	}
}

export default reducer

//selectors

export const selectOffers = state => state.offers.offers
