import React from 'react'
import PropTypes from 'prop-types'
import ProcessingScreen from '../ProcessingScreen'
import ErrorScreen from '../ErrorScreen'
import { prepareProcessing } from '../../redux/actions'
import { connect } from 'react-redux'
import { selectProcessing, selectErrors } from '../../redux/selectors'
import { branch, compose, renderComponent, lifecycle } from 'recompose'

const AppMain = ({ children }) => children

const mapStateToProps = (state, ownProps) => ({
	isProcessing: selectProcessing(state).isProcessing,
	showErrorScreen: selectErrors(state).showErrorScreen,
})

const enhance = compose(
	connect(mapStateToProps),
	lifecycle({
		componentDidMount() {
			let height = document.getElementById('verify-container').clientHeight - 40
			this.props.dispatch(prepareProcessing(height))
		},
		componentWillUpdate(nextProps, nextState) {
			let height = document.getElementById('verify-container').clientHeight - 40
			this.props.dispatch(prepareProcessing(height))
		},
	}),
	branch(({ isProcessing }) => isProcessing,
		renderComponent(ProcessingScreen)
	),
	branch(({ showErrorScreen }) => showErrorScreen,
		renderComponent(ErrorScreen)
	),
)

export default enhance(AppMain)