import * as authorizationApi from '../../api/authorizationApi'

//actions

export const FETCH_AUTHREQUEST_REQUEST = 'FETCH_AUTHREQUEST_REQUEST'
export const FETCH_AUTHREQUEST_SUCCESS = 'FETCH_AUTHREQUEST_SUCCESS'
export const FETCH_AUTHREQUEST_FAILURE = 'FETCH_AUTHREQUEST_FAILURE'

export const fetchAuthRequest = () => dispatch => {
	dispatch({type: FETCH_AUTHREQUEST_REQUEST})
	return authorizationApi.fetchRequestInfo()
		.then(response => {
			dispatch({type: FETCH_AUTHREQUEST_SUCCESS, info: response})
			return response
		})
		.catch(err => {
			dispatch({type: FETCH_AUTHREQUEST_FAILURE, err})
			throw err
		})
}

//reducer

export const STATE_KEY = 'authorization'

export const DEFAULT_STATE = {
	info: {
		client: null,
		scopes: []
	}
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_AUTHREQUEST_SUCCESS:
			return {...state, info: action.info }
		default:
			return {...state}
	}
}

export default reducer

//selectors

export const selectClient = state => state.authorization.info.client

export const selectScopes = state => state.authorization.info.scopes