import ReactGA from 'react-ga'
ReactGA.initialize('UA-24154068-1')

export const GovXGA = {
	set: obj => {
		ReactGA.set(obj)
	},
	event: fields => {
		ReactGA.event(fields)
	},
	pageview: pathname => {
		ReactGA.pageview(pathname)
	}
}

export default GovXGA