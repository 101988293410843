import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import GovXLogo from '../GovXLogo'

const styles = {
	footer: {
		color: props => props.settings.linkColor,

		'@media (max-width: 574px)': {
			height: props => props.settings.showGovXLogo && props.settings.showFooterLinks ? '140px' : props.settings.showGovXLogo ? '85px' : '60px',
			backgroundColor: props => props.settings.linkColor === '#fff' ? '#222' : '#fff',
		},
		'@media (min-width: 575px)': {
			height: '60px',
			position: 'absolute',
			right: '0px',
			bottom: '0px',
			left: '0px',
			margin: '0px auto',
			padding: '0px 30px',
			backgroundColor: props => props.settings.backgroundType === 'transparent' ? 'transparent' : props.settings.backgroundColor,
		},
	},
	list: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0px',
		padding: '0px',

		'@media (max-width: 574px)': {
			height: props => props.settings.showGovXLogo && props.settings.showFooterLinks ? 'auto' : '100%',
		},
		'@media (min-width: 575px)': {
			justifyContent: props => props.settings.showGovXLogo && props.settings.showFooterLinks ? 'flex-end' : 'center',
		},
	},
	listItem: {
		display: 'inline',
		margin: '0px 20px',

		'&:first-child': {
			marginLeft: '0px',
		},
		'&:last-child': {
			marginRight: '0px',
		},
		'@media (max-width: 574px)': {
			fontSize: '12px',
			textAlign: 'center',
		},
	},
	link: {
		color: props => props.settings.linkColor,
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'underline',
		},
	},
	govxLogo: {

		'@media (max-width: 574px)': {
			paddingTop: '30px',
			paddingBottom: props => props.settings.showGovXLogo && props.settings.showFooterLinks ? '30px' : '10px',
			textAlign: 'center',
		},
		'@media (min-width: 575px)': {
			paddingTop: '10px',
			paddingBottom: '5px',
			textAlign: props => props.settings.showGovXLogo && props.settings.showFooterLinks ? 'inherit' : 'center',
			float: props => props.settings.showGovXLogo && props.settings.showFooterLinks ? 'left' : 'none',
		},
	},
}

export const PrivateLabelFooter = ({ classes, settings }) => (
	<div className={ classes.footer }>
		{
			settings.showGovXLogo &&
			<div className={ classes.govxLogo }><GovXLogo settings={ settings } isGovXListing={ false } isCoBrand={ false } /></div>
		}
		{
			settings.showFooterLinks &&
			<ul className={ classes.list }>
				<li className={ classes.listItem }><a href='https://www.govx.com/t/govx-id' className={ classes.link } target='_blank' rel='noopener'>About GOVX ID</a></li>
				<li className={classes.listItem}><a href='https://support.govx.com/section?id=1023' className={ classes.link } target='_blank' rel='noopener'>FAQs</a></li>
				<li className={ classes.listItem }><a href='https://www.govx.com/privacyinfo' className={ classes.link } target='_blank' rel='noopener'>Privacy</a></li>
				<li className={ classes.listItem }><a href='https://www.govx.com/t/termsofservice' className={ classes.link } target='_blank' rel='noopener'>Terms of Service</a></li>
			</ul>
		}
	</div>
)

PrivateLabelFooter.propTypes = {
	classes: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
}

export default injectSheet(styles)(PrivateLabelFooter)