import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { branch, compose, renderComponent, lifecycle } from "recompose";
import injectSheet from "react-jss";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ModalRoot from "../Modals";
import appHeaderFactory from "../AppHeader";
import appFooterFactory from "../AppFooter";
import AppMain from "../AppMain";
import { prepareProcessing } from "../../redux/actions";
import { selectProcessing, selectErrors } from "../../redux/selectors";
import AppTrackers from "./AppTrackers";
import { getListing, getUISettings } from "../../setup/configuration";
import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import ProcessingScreen from "../ProcessingScreen";
import ErrorScreen from "../ErrorScreen";
import GlobalNotification from "../GlobalNotification";
import RouteConstants from "../../setup/route-constants";

const largeScreenWidthPaths = [
  RouteConstants.tickets.entertainerEvents.path + '/',
  RouteConstants.offerEvents.events.path + '/',
]

const styles = {
  scrollBody: {
    "@media (max-width: 574px)": {
      overflowX: "hidden",
    },
    "@media (min-width: 575px)": {
      position: "relative",
      width: "100vw",
      minHeight: (props) => `calc(100vh)`,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: (props) =>
        `${props.uiSettings.header.logoHeight + 60}px 0px ${
          props.uiSettings.footer.showGovXLogo &&
          props.uiSettings.footer.showFooterLinks
            ? 165
            : 100
        }px`,
    },
  },
  container: {
    minWidth: "230px",
    backgroundColor: "#fff",
    flex: 1,

    "@media (max-width: 574px)": {
      width: "calc(100vw)",
      padding: "0px 30px",
    },
    "@media (min-width: 575px)": {
      width: `${
        largeScreenWidthPaths.includes(document.location.pathname) ? "" : "500px"
      }`,
      maxWidth: `${
        largeScreenWidthPaths.includes(document.location.pathname) ? "1000px" : ""
      }`,
      minHeight: "300px",
      display: "flex",
      boxShadow: (props) =>
        props.uiSettings.main.templateType === "CoBranded"
          ? "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px"
          : "none",
    },
  },
  inner: {
    "@media (max-width: 574px)": {
      minWidth: "260px",
      paddingBottom: "25px",
      backgroundColor: "#fff",
      minHeight: (props) =>
        props.uiSettings.footer.showGovXLogo &&
        props.uiSettings.footer.showFooterLinks
          ? props.uiSettings.header.logoOrientation === "landscape"
            ? `calc(100vh - ${props.uiSettings.header.logoHeight + 135}px)`
            : `calc(100vh - ${props.uiSettings.header.logoHeight + 105}px)`
          : props.uiSettings.header.logoOrientation === "landscape"
          ? `calc(100vh - ${props.uiSettings.header.logoHeight + 115}px)`
          : `calc(100vh - ${props.uiSettings.header.logoHeight + 135}px)`,
    },
    "@media (min-width: 575px)": {
      position: "relative",
      width: "350px",
      width: `${
        largeScreenWidthPaths.includes(document.location.pathname) ? "80%" : "350px"
      }`,
      margin: "auto",
      padding: "20px 0px",
    },
  },
  html: {
    "@media (min-width: 575px)": {
      ".appear": {
        transform: "scale(.75, .75)",
        opacity: 0,
      },
      ".appearActive": {
        transform: "scale(1, 1)",
        opacity: 1,
        transition: "transform 300ms ease-in-out, opacity 300ms ease-in-out",
      },
    },
  },
};

export const AppWrapper = ({
  classes,
  children,
  listing,
  uiSettings,
  route,
}) => {
  let listingType = "GovX";
  if (listing.id !== 1) listingType = uiSettings.main.templateType;

  return (
    <div className={classes.scrollBody}>
      {appHeaderFactory(listing, uiSettings, listingType)}

      <TransitionGroup>
        <CSSTransition key={location.key} timeout={500} classNames="fade">
          <div className={classes.container} id="verify-container">
            <div className={classes.inner}>{renderRoutes(route.routes)}</div>
          </div>
        </CSSTransition>
      </TransitionGroup>
      {appFooterFactory(listing, uiSettings, listingType)}
      <GlobalNotification />
      <ModalRoot />
      <AppTrackers />
    </div>
  );
};

AppWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  //children: PropTypes.object.isRequired,
  uiSettings: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  uiSettings: getUISettings(),
  listing: getListing(),
  isProcessing: selectProcessing(state).isProcessing,
  showErrorScreen: selectErrors(state).showErrorScreen,
});

const mapDispatchToProps = (dispatch) => ({
  setProcessingHeight: (height) => dispatch(prepareProcessing(height)),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      let container = document.getElementById("verify-container");
      if (container) {
        let height = container.clientHeight - 40;
        this.props.setProcessingHeight(height);
      }
    },
    componentWillUpdate(nextProps, nextState) {
      let container = document.getElementById("verify-container");
      if (container) {
        let height = container.clientHeight - 40;
        this.props.setProcessingHeight(height);
      }
    },
  }),
  branch(({ isProcessing }) => isProcessing, renderComponent(ProcessingScreen)),
  branch(
    ({ showErrorScreen }) => showErrorScreen,
    renderComponent(ErrorScreen)
  ),
  injectSheet(styles)
);

export default enhance(AppWrapper);
