import React from 'react'
import { compose, lifecycle } from 'recompose'
import loadScript from './loadScript'

export const track = (trackerName, list) => {
	if (!(trackerName && list))
		return

	window[trackerName] = window[trackerName] || []
	for (let i = 0; i < list.length; i++) {
		window[trackerName].push(list[i])
	}
}

// config.getScriptUrl returns the URL to the tracking JS script to be loaded
// config.preload is called once per page load before script load attempt
// config.success is called once per page load after successful script load
export const composeTracking = config => compose(
	lifecycle({
		componentDidMount() {
			let info = {}
			let url = config.getScriptUrl(info)
			let preload = config.preload ? config.preload : (x => x)
			let success = config.success ? config.success : (x => x)
			let ready = config.ready ? config.ready : (x => x)
			loadScript(url, info, preload, success).then(() => ready(info))
		}
	})
)