import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import GovXLogo from '../GovXLogo'

const styles = {
	footer: {
		backgroundColor: '#ecf0f1',

		'@media (max-width: 574px)': {
			height: '140px',
			boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		},
		'@media (min-width: 575px)': {
			height: '125px',
			position: 'absolute',
			right: '0px',
			bottom: '0px',
			left: '0px',
			margin: '0px auto',
		},
	},
	list: {
		height: '60px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0px',
		padding: '0px',

		'@media (max-width: 574px)': {
			height: 'auto',
		},
	},
	listItem: {
		display: 'inline',
		margin: '0px 20px',
		fontSize: '14px',
		'@media (max-width: 574px)': {
			fontSize: '12px',
			textAlign: 'center',
		},
		'&:first-child': {
			marginLeft: '0px',
		},
		'&:last-child': {
			marginRight: '0px',
		},
	},
	link: {
		color: '#2a2d36',
		textDecoration: 'none',

		'&:hover': {
			textDecoration: 'underline',
		},
	},
	govxLogo: {
		textAlign: 'center',
		display: 'block',
		'@media (max-width: 574px)': {
			padding: '30px 0px',
		},
		'@media (min-width: 575px)': {
			padding: '20px 0px 0px',
		},
	},
}

export const CoBrandedFooter = ({ classes, settings }) => (
	<div className={ classes.footer }>
		<div className={ classes.govxLogo }>
			<GovXLogo settings={ settings } isGovXListing={ false } isCoBrand={ true } />
		</div>
		<ul className={ classes.list }>
			<li className={ classes.listItem }><a href='https://www.govx.com/t/govx-id' className={ classes.link } target='_blank' rel='noopener'>About GOVX ID</a></li>
			<li className={classes.listItem}><a href='https://support.govx.com/section?id=1023' className={ classes.link } target='_blank' rel='noopener'>FAQs</a></li>
			<li className={ classes.listItem }><a href='https://www.govx.com/privacyinfo' className={ classes.link } target='_blank' rel='noopener'>Privacy</a></li>
			<li className={ classes.listItem }><a href='https://www.govx.com/t/termsofservice' className={ classes.link } target='_blank' rel='noopener'>Terms of Service</a></li>
		</ul>
	</div>
)

CoBrandedFooter.propTypes = {
	classes: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
}

export default injectSheet(styles)(CoBrandedFooter)