//Note that we convert all query string parameters to lower.
export const getParams = () => {
	let query = window.location.search

	if (!query)
		return {}

	return (/^[?#]/.test(query) ? query.slice(1) : query)
		.split('&')
		.reduce((params, param) => {
			let [ key, value ] = param.split('=')
			params[key.toLowerCase()] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
			return params
		}, {})
}

export const getAllParams = (isEncoded) => {
	let query = window.location.search

	if (!query)
		return {}

  if (isEncoded) {
		query = decodeURIComponent(query)
	}

	return (/^[?#]/.test(query) ? query.slice(1) : query)
		.split('&')
		.reduce((params, param) => {
			let [ key, value ] = param.split('=')
			params[key.toLowerCase()] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
			return params
		}, {})
}