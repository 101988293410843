import React from 'react'
import Loadable from 'react-loadable'
import RouteConstants from '../../../setup/route-constants'
import { isLoggedIn } from '../../../redux/selectors'
import { requireUser } from '../../../redux/actions'

const LoadableVerifyDocPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "verification.verify.doc" */ './VerifyDocPageContainer'),
	loading: () => null
})

const LoadableVerifyDocSubTypePageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "verification.verify.doc.subtype" */ './VerifyDocSubTypeContainer'),
	loading: () => null
})

const LoadableVerifyDocUploadPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "verification.verify.doc.upload" */ './VerifyDocUploadContainer'),
	loading: () => null
})

export const DocTypeAsync = {
	name: 'verification verify doc sub-type',
	path: RouteConstants.verification.verifyDoc.subType.path,
	page: true,
	component: LoadableVerifyDocSubTypePageContainer,
	lifecycle: {
		authorize: (match, location, { getState, dispatch }, history) => {
			return dispatch(requireUser()).then(response => {
				if (!isLoggedIn(getState())) {
					history.replace({ pathname: '/login', search: `?returnUrl=${location.pathname}` })
					return { authorized: false }
				}
				return { authorized: true }
			})
		},
	},
	config: () => ({

	})
}

export const DocUploadAsync = {
	name: 'verification verify doc upload',
	path: RouteConstants.verification.verifyDoc.upload.path,
	page: true,
	component: LoadableVerifyDocUploadPageContainer,
	lifecycle: {
		authorize: (match, location, { getState, dispatch }, history) => {
			return dispatch(requireUser()).then(response => {
				if (!isLoggedIn(getState())) {
					history.replace({ pathname: '/login', search: `?returnUrl=${location.pathname}` })
					return { authorized: false }
				}
				return { authorized: true }
			})
		},
	},
	config: () => ({

	})
}

export default {
	name: 'verification verify doc',
	path: RouteConstants.verification.verifyDoc.index.path,
	exact: true,
	page: true,
	component: LoadableVerifyDocPageContainer,
	lifecycle: {
		authorize: (match, location, { getState, dispatch }, history) => {
			return dispatch(requireUser()).then(response => {
				if (!isLoggedIn(getState())) {
					history.replace({ pathname: '/login', search: `?returnUrl=${location.pathname}` })
					return { authorized: false }
				}
				return { authorized: true }
			})
		},
	},
	config: () => ({

	})
}