import 'babel-polyfill'
import React from 'react'
import FontFaceObserver from 'fontfaceobserver' // fontfaceobserver.com
import * as govxStorage from '../localStorage'

export function loadFontFaceObserver() {

	if (!govxStorage.getFontsLoaded()) {

		var font = new FontFaceObserver('Geomanist Regular', { weight: 300 });

		font.load().then(function () {
			document.body.classList.add('fonts-loaded')
			govxStorage.setFontsLoaded()
		});

		// Promise.all([
		// 	new FontFaceObserver('Geomanist Regular', { weight: 300 }).check(),
		// ])
		// .then(() => {
		//
		// 	document.body.classList.add('fonts-loaded')
		// 	govxStorage.setFontsLoaded()
		// })
		// .catch(err => {
		// })
	} else {
		document.body.classList.add('fonts-loaded')
	}
}