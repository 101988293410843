import React from 'react'
import Loadable from 'react-loadable'
import PageOverlay from '../../../components/PageOverlay'
import RouteConstants from '../../../setup/route-constants'
import { isLoggedIn } from '../../../redux/selectors'
import { requireUser } from '../../../redux/actions'


const LoadableVerifyDMDCFamilyPageContainer = Loadable({
	loader: () => import(/* webpackChunkName: "verification.verify.dmdcfamily" */ './VerifyDMDCFamilyPageContainer'),
	loading: () => <PageOverlay />,
})

export default {
	name: 'verification verify dmdc family',
	path: RouteConstants.verification.verifyDmdcFamily.path,
	page: true,
	component: LoadableVerifyDMDCFamilyPageContainer,
	lifecycle: {
		authorize: (match, location, { getState, dispatch }, history) => {
			return dispatch(requireUser()).then(response => {
				if (!isLoggedIn(getState())) {
					history.replace({ pathname: '/login', search: `?returnUrl=${location.pathname}` })
					return { authorized: false }
				}
				return { authorized: true }
			})
		},
	},
	config: () => ({}),
}
