import * as shopifyApi from '../../api/shopifyApi'
//actions

export const FETCH_SHOPIFY_DISCOUNT_REQUEST = 'FETCH_SHOPIFY_DISCOUNT_REQUEST'
export const FETCH_SHOPIFY_DISCOUNT_SUCCESS = 'FETCH_SHOPIFY_DISCOUNT_SUCCESS'
export const FETCH_SHOPIFY_DISCOUNT_FAILURE = 'FETCH_SHOPIFY_DISCOUNT_FAILURE'

export const fetchShopifyDiscount = () => (dispatch, getState) => {
	dispatch({type: FETCH_SHOPIFY_DISCOUNT_REQUEST})
	return shopifyApi.fetchShopifyDiscount()
		.then(resp => {
			dispatch({type: FETCH_SHOPIFY_DISCOUNT_SUCCESS, discount: resp})
		})
		.catch(err => {
			dispatch({type: FETCH_SHOPIFY_DISCOUNT_FAILURE, err})
			throw err
		})
}

export const STATE_KEY = 'shopify'

export const DEFAULT_STATE = {
	discount: null
}

const reducer = (state = DEFAULT_STATE, action) => {
	switch(action.type) {
		case FETCH_SHOPIFY_DISCOUNT_SUCCESS:
			return {...state, discount: action.discount }
		default:
			return {...state}
	}
}

export default reducer

//selectors

export const selectShopifyDiscount = state => state.shopify.discount
